import { RuntimeLogger } from "./runtimeLogger";

class ConsoleLogger implements RuntimeLogger {
  public name: string;

  constructor(name = "ace-designer-runtime-console") {
    this.name = name;
  }

  private callConsoleLog(method: string, message: unknown, metaData?: unknown) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const methodInstance = console[method];
    if (methodInstance) {
      methodInstance(
        "module: " + this.name,
        ", message: ",
        message,
        ", metaData: ",
        metaData || {}
      );
    } else {
      console.log(
        "module: " + this.name,
        ", message: ",
        message,
        ", metaData: ",
        metaData || {}
      );
    }
  }

  public debug(message: unknown, metaData?: unknown): void {
    this.callConsoleLog("debug", message, metaData);
  }

  public info(message: unknown, metaData?: unknown): void {
    this.callConsoleLog("info", message, metaData);
  }

  public warn(message: unknown, metaData?: unknown): void {
    this.callConsoleLog("warn", message, metaData);
  }

  public error(message: unknown, metaData?: unknown): void {
    this.callConsoleLog("error", message, metaData);
  }

  public log(level: string, message: unknown, metaData: unknown): void {
    this.callConsoleLog(level, message, metaData);
  }

  public isInfoEnabled(): boolean {
    return true;
  }

  public isDebugEnabled(): boolean {
    return true;
  }

  public isErrorEnabled(): boolean {
    return true;
  }

  public isWarnEnabled(): boolean {
    return true;
  }
}

export default ConsoleLogger;
