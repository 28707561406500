import { createSelector } from "@reduxjs/toolkit";
import { initialPageStateData } from "store/designer/initialState";

import { Pages } from "../../../model";
import { ObjKeys } from "../../../utils/ObjectUtils";
import { toSnakeCase } from "../../designer/caseReducers/utils";
import { RootState } from "../../index";

export const selectPage = <T extends keyof Pages>(
  state: RootState,
  page: T
): Pages[T] => state.designer.pages[page];

export const createPageSelector =
  <T extends keyof Pages>(page: T) =>
  (state: RootState): Pages[T] =>
    selectPage(state, page);

export const createPageParamSelector =
  <TPage extends keyof Pages, Page = Pages[TPage]>(
    pageSelector: (state: RootState) => Page
  ) =>
  <TParam extends keyof Page>(
    param: TParam
  ): ((state: RootState) => Page[TParam]) =>
    createSelector(pageSelector, (page) => page[param]);

type SelectorKey<TPage, TKey> = `select${Capitalize<
  string & TPage
>}${Capitalize<string & TKey>}`;

type PageParamSelectors<TPage extends keyof Pages> = {
  [TParam in keyof Pages[TPage] as SelectorKey<TPage, TParam>]: (
    state: RootState
  ) => Pages[TPage][TParam];
};

export const createAllPageParamSelectors = <TPage extends keyof Pages>(
  page: TPage
): PageParamSelectors<TPage> => {
  const pageSelector = createPageSelector(page);
  const pageParamSelector = createPageParamSelector(pageSelector);

  return ObjKeys(initialPageStateData[page]).reduce((acc, param) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    acc[toSnakeCase("select", page, param)] = pageParamSelector(param);
    return acc;
  }, {} as PageParamSelectors<TPage>);
};
