export const deleteIndexedDb = async (): Promise<string> =>
  new Promise((res, rej) => {
    const DBDeleteRequest = window.indexedDB.deleteDatabase("ACE");

    DBDeleteRequest.onerror = (_event) => {
      rej("Error in deleting index db 'ACE'");
    };

    DBDeleteRequest.onsuccess = (_event) => {
      res("Successfully deleted indexedDB 'ACE'");
    };
  });
