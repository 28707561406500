import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Entity } from "../../store/utils/redoableSliceFactory";
import { selectSelectedWorkspaceId } from "../../store/workspaces/selectors";

export enum Status {
  New = "NEW",
  Deselected = "",
}

type SelectId = string;
export type Selected = SelectId | Status;

type UseWatchedSelectReturn = {
  selected: Selected;
  setSelected: (selected: Selected) => void;
  validateUrlSelected: (entitiesList: Entity[], navigatePath: string) => void;
  clearUrlSelected: (navigatePath: string) => void;
};

export type UseWatchedSelectParams = {
  reduxSelectedId?: Selected;
  onSelect?: (selected: Selected) => void;
  watchUrlChanges?: boolean;
};

export function useWatchedSelect(
  params?: UseWatchedSelectParams
): UseWatchedSelectReturn {
  const { watchUrlChanges } = params || {};
  const workspaceId = useSelector(selectSelectedWorkspaceId);
  const [searchParams] = useSearchParams();
  const { hash, pathname } = useLocation();
  const [shouldValidateUrlSelected, setShouldValidateUrlSelected] =
    useState(false);
  const [urlSelectedId, setUrlSelectedId] = useState(
    `${searchParams.get("selectedId") ?? ""}${hash}`
  );

  const reduxSelectedId = params?.reduxSelectedId ?? Status.Deselected;
  const [selected, setSelectedRaw] = useState<Selected>(
    urlSelectedId || reduxSelectedId
  );

  const setSelected = useCallback(
    (selected: Selected) => {
      setSelectedRaw(selected);
      if (params?.onSelect) params.onSelect(selected);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /* synchronizes selected id with redux */
  useEffect(() => {
    setSelected(reduxSelectedId);
  }, [reduxSelectedId, setSelected]);

  const navigate = useNavigate();

  useEffect(() => {
    if (watchUrlChanges) {
      setUrlSelectedId(`${searchParams.get("selectedId")}${hash}`);
    }
  }, [watchUrlChanges, searchParams, hash]);

  useEffect(() => {
    if (urlSelectedId) {
      setShouldValidateUrlSelected(true);
    }

    const value = isSet(urlSelectedId)
      ? urlSelectedId
      : reduxSelectedId || Status.Deselected;
    setSelected(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelected, urlSelectedId, workspaceId]);

  const validateUrlSelected = useCallback(
    (entitiesList: Entity[], navigatePath: string) => {
      if (!shouldValidateUrlSelected) {
        return;
      }

      if (isSet(urlSelectedId) && entitiesList.length) {
        if (!entitiesList.find((o) => o.id === urlSelectedId)) {
          setUrlSelectedId("");
          setSelected(Status.Deselected);
          navigate(`/${navigatePath}`, { replace: true });
        }

        setShouldValidateUrlSelected(false);
      }
    },
    [setSelected, shouldValidateUrlSelected, urlSelectedId, navigate]
  );

  const clearUrlSelected = useCallback(
    (navigatePath: string) => {
      setUrlSelectedId("");
      navigate(`/${navigatePath}`, { replace: true });
    },
    [navigate]
  );

  useEffect(() => {
    if (isSet(urlSelectedId) && selected !== urlSelectedId) {
      clearUrlSelected(pathname.slice(1));
    }
  }, [urlSelectedId, selected, pathname, clearUrlSelected]);

  return { selected, setSelected, validateUrlSelected, clearUrlSelected };
}

function isSet(urlSelectedId: string) {
  return (
    urlSelectedId !== "" &&
    urlSelectedId !== "undefined" &&
    urlSelectedId !== "null"
  );
}
