import { StepSchema, Steps } from "../model";

export const switchStepSchema: StepSchema = {
  stepType: Steps.SWITCH,
  hideForAce4: true,
  jsonSchema: {
    description: "Switch step",
    title: "Switch",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
