import { split } from "@sapiens-digital/ace-designer-common/lib/helpers/paths";
import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import YAML from "yaml";

import { readdirDeep, writeYaml } from "../../fs-utils";
import { getWorkspaceFS } from "../workspace";

import { PlainObject, V1Entities, V1Folder } from "./migrate";

export const extractFileName = (
  content: unknown,
  defaultName: string
): string => get(content, "name", defaultName);

export const saveAsYaml = async (
  targetRepositoryLocation: string,
  targetFolder: string,
  extlessName: string,
  content: unknown,
  yamlOptions: YAML.SchemaOptions
): Promise<void> => {
  const dirname = posixPath.join(targetRepositoryLocation, targetFolder);
  const location = posixPath.join(dirname, extlessName.concat(".yaml"));

  await writeYaml(location, content, yamlOptions);
};

export const readJsonFileAsPlainObject = async (
  filePath: string
): Promise<PlainObject> => {
  const fs = getWorkspaceFS();

  const content = JSON.parse(
    await fs.promises.readFile(filePath, { encoding: "utf8" })
  );
  return isPlainObject(content) ? content : {};
};

export const getV1WorkspaceEntityPaths = async (
  workspaceLocation: string
): Promise<V1Entities> => {
  const folderNames = Object.values(V1Folder);
  const jsonFiles = await readdirDeep(workspaceLocation, {
    filter: (location) => location.endsWith(".json"),
  });

  const entities = {} as V1Entities;

  for (const folder of folderNames) {
    entities[folder] = jsonFiles.filter((file) =>
      isInFolder(workspaceLocation, file, folder)
    );
  }

  return entities;
};

const isInFolder = (
  workspaceLocation: string,
  file: string,
  folder: string
): boolean => {
  const relative = posixPath.relative(workspaceLocation, file);
  return split(relative)[0] === folder;
};
