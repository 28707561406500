import { createLogger } from "./loggerFactory";
import { RuntimeLogger } from "./runtimeLogger";

class Logger {
  private static createLogger: (name?: string) => RuntimeLogger;

  public static setLoggerFactory(
    createLogger: (name?: string) => RuntimeLogger
  ): void {
    Logger.createLogger = createLogger;
  }

  public static getLogger(name?: string): RuntimeLogger {
    if (Logger.createLogger) {
      return Logger.createLogger(name);
    } else {
      return createLogger(name);
    }
  }
}

export { Logger };
