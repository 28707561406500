import { OpenAPIV3 } from "openapi-types";
import cloneDeep from "lodash/cloneDeep";

import { AceApiOperation, SerializedApi, WORKSPACE_PATHS } from "../model";
import { getApiOperationList } from "./getApiOperationList";
import { mapLocalRefsToOpenApiFormat } from "./mapLocalRefsToOpenApiFormat";
import { ReferenceResolution } from "../references";

const getApiResponses = (
  responses: OpenAPIV3.ResponsesObject
): OpenAPIV3.ResponsesObject => {
  const newResponses: OpenAPIV3.ResponsesObject = cloneDeep(responses);

  Object.entries(newResponses).forEach(([, response]) => {
    const modifiedResponse = response as OpenAPIV3.ResponseObject;

    if (modifiedResponse.content?.["application/json"]) {
      modifiedResponse.content["application/xml"] = cloneDeep(
        modifiedResponse.content["application/json"]
      );
    }
  });

  return newResponses;
};

const removeUndefinedProperties = (object: { [p: string]: unknown }) => {
  for (const key in object) {
    if (object[key] === undefined) {
      delete object[key];
    }
  }
};

export const createOpenApiSchema = async (
  refResolution: ReferenceResolution,
  stateApi: SerializedApi,
  apiOperations?: AceApiOperation[]
): Promise<Partial<OpenAPIV3.Document>> => {
  const specWrapper: Partial<OpenAPIV3.Document> = {
    openapi: "3.0.0",
    info: stateApi.info,
    paths: {},
    components: { schemas: {} },
  };

  const apiId = "export";
  const selectedOperations =
    apiOperations ?? getApiOperationList(stateApi.paths, apiId);

  const securitySchemeKey = "bearerAuth";

  for (const op of selectedOperations) {
    const scheme: OpenAPIV3.HttpSecurityScheme = {
      type: "http",
      scheme: "bearer",
      bearerFormat: "JWT",
    };

    type SecuritySchemes = { [key: string]: OpenAPIV3.SecuritySchemeObject };
    const securitySchemes: SecuritySchemes = { [securitySchemeKey]: scheme };

    if (specWrapper.components) {
      specWrapper.components.securitySchemes = securitySchemes;
    }

    const schema: OpenAPIV3.OperationObject = {
      operationId: op.operationId,
      summary: op.summary,
      description: op.description,
      tags: op.tags,
      parameters: op.parameters,
      requestBody: op.requestBody,
      responses: getApiResponses(op.responses),
      security: [{ [securitySchemeKey]: [] }],
    };

    removeUndefinedProperties(schema);

    if (specWrapper.paths && specWrapper.paths[op.path]) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      specWrapper.paths[op.path]![op.verb] = schema;
    } else {
      specWrapper.paths = {
        ...specWrapper.paths,
        [op.path]: {
          [op.verb]: schema,
        },
      };
    }
  }

  const apiResolvedReferences = await refResolution.deepResolveSchemaObject(
    specWrapper,
    WORKSPACE_PATHS.APIS
  );

  return mapLocalRefsToOpenApiFormat(apiResolvedReferences);
};
