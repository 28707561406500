import { SerializedApi } from "@sapiens-digital/ace-designer-common/lib/model";
import { OpenAPIV3 } from "openapi-types";
import { Entity } from "store/utils/redoableSliceFactory";

export interface Api extends SerializedApi {
  id: string;
  name: string;
}

export type OperationId = {
  apiId: string;
  path: string;
  verb: OpenAPIV3.HttpMethods;
} & Entity;

export const SUPPORTED_MEDIA_TYPES = [
  "application/json",
  "multipart/form-data",
] as const;

export type ApiOperationMediaType = (typeof SUPPORTED_MEDIA_TYPES)[number];
