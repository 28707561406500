import { WORKSPACE_PATHS } from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import get from "lodash/get";
import set from "lodash/set";
import { createSortYamlMapEntries } from "utils/sortYaml";

import { YAMLStringifyOptions } from "../../fs-utils";

import { V1EntityMigrator } from "./migrate";
import { saveAsYaml } from "./migrateUtils";

const ERROR_HANDLER_KEYS = ["errorPattern", "errorCode", "errorMessage"];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(ERROR_HANDLER_KEYS),
};

const extractErrorHandlerTag = (
  content: unknown,
  defaultName: string
): string => get(content, "tag", defaultName);

const migrateErrorHandler: V1EntityMigrator = async ({
  targetRepositoryLocation,
  fileBasename,
  content,
}) => {
  const migration = {};
  const name = extractErrorHandlerTag(content, fileBasename);
  set(migration, "errorPattern", get(content, "match_string"));
  set(migration, "errorCode", get(content, "error_code"));
  set(migration, "errorMessage", get(content, "error_message"));
  await saveAsYaml(
    targetRepositoryLocation,
    WORKSPACE_PATHS.ERROR_HANDLERS,
    name,
    migration,
    yamlOptions
  );
  return [];
};

export default migrateErrorHandler;
