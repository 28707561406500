import { DevelopmentRequestMetadata } from "../model/aceHeaders";
import getErrorMessage from "./getErrorMessage";

export const getBase64EncodedHeaders = (
  headers: Record<string, unknown>
): string => {
  try {
    return Buffer.from(JSON.stringify(headers)).toString("base64");
  } catch (err) {
    throw new Error(`Error in encoding headers`);
  }
};

export const getBase64DecodedHeaders = (
  base64Value: string
): DevelopmentRequestMetadata => {
  if (!base64Value) {
    throw new Error(`No header value to decode: ${base64Value}`);
  }
  try {
    return JSON.parse(Buffer.from(base64Value, "base64").toString());
  } catch (err) {
    throw new Error(`Error in decoding header: ${getErrorMessage(err)}`);
  }
};
