import { Designer, StepUi, TabBaseUiConfig } from "../../model/designer";

export const setUiData = (
  state: Designer,
  args: { flowId?: string; schedulerId?: string; stepId?: string },
  data: Partial<TabBaseUiConfig>
): void => {
  const { flowId, schedulerId, stepId } = args;

  if (stepId) {
    state.stepUi[stepId] = {
      ...state.stepUi[stepId],
      ...data,
    };
    return;
  }

  if (flowId) {
    state.flowUi[flowId] = {
      ...state.flowUi[flowId],
      ...data,
    };
    return;
  }

  if (schedulerId) {
    state.schedulerUi[schedulerId] = {
      ...state.schedulerUi[schedulerId],
      ...data,
    };
  }
};

export const resetAllOpenFlowsData = (
  state: Designer,
  idToExclude?: string
): void => {
  if (!idToExclude) {
    state.selectedStepIds = {};
    state.flowUi = {};
    state.stepUi = {};
    state.flowsSourceViewEnabled = {};
  } else {
    state.selectedStepIds = {
      [idToExclude]: state.selectedStepIds[idToExclude],
    };
    state.flowUi = { [idToExclude]: state.flowUi[idToExclude] };

    const updatedStateStepUI: Record<string, StepUi> = {};

    for (const key in state.stepUi) {
      if (state.stepUi[key].flowId === idToExclude) {
        updatedStateStepUI[key] = state.stepUi[key];
      }
    }

    state.stepUi = updatedStateStepUI;

    state.flowsSourceViewEnabled = {
      [idToExclude]: state.flowsSourceViewEnabled[idToExclude],
    };
  }
};
