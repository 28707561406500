import { createAction, createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import { UserSettings } from "@sapiens-digital/ace-designer-common";
import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { isDir } from "services/fs-utils";

import { NotificationOptionalKey } from "../../model/notification";
import { GitDetails } from "../../model/workspace";
import { getACEVersion } from "../../services/designer";
import {
  gitCloneWithCredentials,
  gitRepositoryUrl,
} from "../../services/git-utils";
import { SettingsManager } from "../../services/settingsManager";
import {
  createTemporaryFolder,
  resetTemporaryFiles,
} from "../../services/temporaryFiles";
import {
  deleteWorkspace,
  getWorkspaceFolderNames,
} from "../../services/workspace";
import { extractZipToFolder } from "../../services/zip";
import {
  detectRepositoryChangeThunk,
  loadDesignerSettingsAction,
  switchWorkspaceAction,
} from "../settings/actions";
import { loadStepsSchemas } from "../steps-schemas/actions";
import { readError } from "../utils/readError";
import {
  createWorkspaceAction,
  loadWorkspacesAction,
} from "../workspaces/actions";
import { RootState } from "..";

export const initializeDesignerAction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("designer/initialize", async (_, { dispatch }) => {
  await SettingsManager.init(); //TODO: this is duplicate init, remove
  await dispatch(getACEVersionAction());
  await dispatch(loadStepsSchemas()).unwrap();
  const settings = await dispatch(loadDesignerSettingsAction()).unwrap();

  const workspacesLocation = SettingsManager.getWorkspacesLocation();

  if (workspacesLocation === undefined) {
    throw new Error("workspacesLocation is undefined");
  }

  try {
    await resetTemporaryFiles(workspacesLocation);
  } catch (e) {
    console.error("Failed to clear temporary files:", readError(e));
  }

  const folders = await getWorkspaceFolderNames(workspacesLocation);

  if (folders.length > 0) {
    const existingRepoUrl = await gitRepositoryUrl(
      posixPath.join(workspacesLocation, folders[0])
    );

    if (existingRepoUrl !== settings.repositoryUrl) {
      console.debug("Delete local workspaces");
      await Promise.all(
        folders.map((folder) =>
          deleteWorkspace(posixPath.join(workspacesLocation, folder))
        )
      );
    }
  }

  await dispatch(initializeWorkspacesThunk(settings)).unwrap();
});

export const loginDesignerThunk = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("designer/login", async (_, { dispatch, getState }) => {
  const { settings: oldSettings } = getState();
  await dispatch(loadDesignerSettingsAction()).unwrap();
  await dispatch(detectRepositoryChangeThunk(oldSettings)).unwrap();
});

export const initializeWorkspacesThunk = createAsyncThunk<
  void,
  UserSettings,
  { state: RootState }
>("designer/initializeWorkspaces", async (settings, { dispatch }) => {
  const workspaces = await dispatch(loadWorkspacesAction(settings)).unwrap();

  if (!workspaces.length) {
    const createAction = await dispatch(
      createWorkspaceAction({
        ...settings,
        workspaceName: settings.repositoryDefaultBranch,
      })
    );

    if (isRejected(createAction)) {
      throw new Error("Failed to create workspace");
    }

    await dispatch(switchWorkspaceAction(createAction.payload.id)).unwrap();
    return;
  }

  if (settings.selectedWorkspaceId !== undefined) {
    const selectedWorkspaceExists = workspaces.some(
      (item) => item.id === settings.selectedWorkspaceId
    );

    if (selectedWorkspaceExists) {
      await dispatch(
        switchWorkspaceAction(settings.selectedWorkspaceId)
      ).unwrap();
      return;
    }
  }

  const defaultBranchWorkspace = workspaces.find(
    (item) => item.name === settings.repositoryDefaultBranch
  );

  if (defaultBranchWorkspace !== undefined) {
    await dispatch(switchWorkspaceAction(defaultBranchWorkspace.id)).unwrap();
    return;
  }

  await dispatch(switchWorkspaceAction(workspaces[0].id)).unwrap();
  return;
});

export const getACEVersionAction = createAsyncThunk(
  "designer/getACEVersion",
  async () => getACEVersion()
);

export const setWorkspaceHasConflictsFlagAction = createAction<void>(
  "designer/setWorkspaceHasConflictsFlag"
);

export const showLocalRepositoryWarning = createAction(
  "designer/showLocalRepositoryWarning"
);
export const hideLocalRepositoryWarning = createAction(
  "designer/hideLocalRepositoryWarning"
);

export const unzipToTemporaryDirectory = createAsyncThunk<
  string,
  { zipData: Buffer },
  { state: RootState }
>("designer/unzipToTemporaryDirectory", async ({ zipData }) => {
  const folderPath = await createTemporaryFolder(
    SettingsManager.getWorkspacesLocation()
  );

  await extractZipToFolder(folderPath, zipData);
  return folderPath;
});

export const pullGitRepositoryToTemporaryDirectory = createAsyncThunk<
  string,
  { gitData: GitDetails },
  { state: RootState }
>("designer/pullGitRepositoryToTemporaryDirectory", async ({ gitData }) => {
  const { gitUrl, branchOrTagName, gitUsername, gitPassword, sourcePath } =
    gitData;

  const folderPath = await createTemporaryFolder(
    SettingsManager.getWorkspacesLocation()
  );

  await gitCloneWithCredentials(
    gitUrl,
    branchOrTagName,
    gitUsername,
    gitPassword,
    folderPath
  );

  if (sourcePath) {
    const srcPath = posixPath.join(folderPath, sourcePath);

    if (!(await isDir(srcPath))) {
      throw new Error(`Invalid path to ACE workspace: ${sourcePath}`);
    }

    return srcPath;
  }

  return folderPath;
});

export const addNotification = createAction<NotificationOptionalKey>(
  "designer/addNotification"
);
