import { getCommonPageDataCaseReducers } from "./caseReducers/CommonPageDataCaseReducers";
import { getFileTreeDataCaseReducers } from "./caseReducers/FileTreeCaseReducers";

export const pageCaseReducers = {
  ...getCommonPageDataCaseReducers("api"),
  ...getFileTreeDataCaseReducers("api"),
  ...getCommonPageDataCaseReducers("flow"),
  ...getFileTreeDataCaseReducers("flow"),
  ...getCommonPageDataCaseReducers("schemas"),
  ...getFileTreeDataCaseReducers("schemas"),
  ...getCommonPageDataCaseReducers("virtualSteps"),
  ...getFileTreeDataCaseReducers("virtualSteps"),
  ...getCommonPageDataCaseReducers("scheduler"),
  ...getCommonPageDataCaseReducers("handlers"),
  ...getCommonPageDataCaseReducers("variables"),
  ...getFileTreeDataCaseReducers("variables"),
};
