import { createAsyncThunk } from "@reduxjs/toolkit";

import { ExtendedSettings } from "../../model/workspace";
import { updateWorkspace } from "../../services/workspace";

export const updateWorkspaceAction = createAsyncThunk(
  "workspaces/update",
  async (parameters: ExtendedSettings) =>
    await updateWorkspace(
      parameters.workspaceName,
      parameters.repositoryWorkspacePath,
      parameters.repositoryUrl,
      parameters.repositoryToken,
      parameters.repositoryUsername
    )
);
