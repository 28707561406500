import { Steps } from "model";

export const COMPLEX_STEPS = [
  Steps.SWITCH as string,
  Steps.CONDITION as string,
];

const isNestedStep = (stepType: string): boolean =>
  COMPLEX_STEPS.includes(stepType);

export { isNestedStep };
