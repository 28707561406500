import { createAsyncThunk } from "@reduxjs/toolkit";
import { DEFAULT_VARIABLES_FILENAME } from "@sapiens-digital/ace-designer-common";
import { v4 as uuid4 } from "uuid";

import { RootState } from "../index";
import { selectFolder } from "../workspaces/selectors";

import { add } from "./reducers";
import { name } from "./sliceName";

export const initializeVariablesAction = createAsyncThunk(
  `${name}/initialize`,
  async (_, { dispatch, getState }): Promise<string> => {
    const state = getState() as RootState;
    const variableFileId = selectFolder(state, "variables").find(
      (n) => n.displayName === DEFAULT_VARIABLES_FILENAME
    )?.id;

    if (!variableFileId) {
      const id = uuid4();
      dispatch(
        add({ id: id, variables: [], name: DEFAULT_VARIABLES_FILENAME })
      );
      return id;
    } else {
      return variableFileId;
    }
  }
);
