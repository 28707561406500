type Plugin = {
  wrapComponents: {
    errors: () => () => null;
  };
};

export const HideAllErrorsPlugin = (): Plugin => ({
  wrapComponents: {
    errors: () => () => null,
  },
});
