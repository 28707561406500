import { capitalize } from "@mui/material";

export type DistributedRecord<K extends PropertyKey, V> = {
  [P in K]: { [Q in P]: V };
}[K];
export const keyVal = <K extends PropertyKey, V>(
  k: K,
  v: V
): DistributedRecord<K, V> =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ [k]: v } as any);

export type SnakeCase<
  T1 extends string,
  T2 extends string,
  T3 extends string = ""
> = `${T1}${Capitalize<T2>}${Capitalize<T3>}`;

export function toSnakeCase<
  S1 extends string,
  S2 extends string,
  S3 extends string = ""
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
>(s1: S1, s2: S2, s3: S3 = ""): SnakeCase<S1, S2, S3> {
  return `${s1}${capitalize(s2)}${capitalize(s3)}` as SnakeCase<S1, S2, S3>;
}
