import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../store";
import { selectSelectedWorkspaceId } from "../store/workspaces/selectors";

import { FLOW_EDITOR_PATH, FLOWS_PATH } from "./Designer";

/**
 * Redirects to flows page when workspace is changed and flow editor is opened
 */
export const useRouteAdjuster = (): void => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const selectedWorkspaceId = useAppSelector(selectSelectedWorkspaceId);
  const prevSelectedWorkspaceId = useRef(selectedWorkspaceId);

  useEffect(() => {
    if (
      pathname === `/${FLOWS_PATH}/${FLOW_EDITOR_PATH}` &&
      prevSelectedWorkspaceId.current !== undefined &&
      selectedWorkspaceId !== prevSelectedWorkspaceId.current
    ) {
      navigate(`/${FLOWS_PATH}`);
    }

    prevSelectedWorkspaceId.current = selectedWorkspaceId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspaceId]);
};
