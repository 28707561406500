import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import YAML from "yaml";

import { Workspace, WorkspaceFolder } from "../../model/workspace";
import {
  deleteEntityFolder,
  moveEntityFolder,
  renameEntityFolder,
  saveEntityFolder,
} from "../../services/entityFolder";
import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  MoveFolder,
  SaveEntity,
} from "../../services/entityService.types";
import {
  getFileNameFromRegistry,
  isFileRenamed,
} from "../../services/fs-utils";
import { fsActionQueue } from "../../services/fsActionQueue";
import {
  PushChangesToGit,
  pushChangesToGit,
  pushModifiedFolderToGit,
} from "../../services/git-utils";
import { findNode } from "../../services/nodes";
import { RootState } from "../index";
import {
  hideEntityFileFromWorkspaceAction,
  updateWorkspaceFolderAction,
} from "../workspaces/actions";
import {
  selectSelectedWorkspace,
  workspaceSelectors,
} from "../workspaces/selectors";
import { selectFolderTreeFolderNodePath } from "../workspaces/treeNodeSelectors";

import { assertSettings, assertWorkspace } from "./assertEntities";
import { getEntityTargetPath } from "./getEntityTargetPath";
import { Entity } from "./redoableSliceFactory";
import { getRedoableSlice, selectEntity } from "./redoableSliceSelectors";

export type RefreshEntitiesAction<T extends Entity> = AsyncThunk<
  Record<string, { entity: T; draft?: string } | null>,
  { wsId: string; entityIds: string[] },
  { state: unknown }
>;

/**
 * Refreshes (from file) entities specified by 'entityIds'
 */
export function refreshEntitiesActionCreator<T extends Entity>(
  sliceName: string,
  getEntity: (id: string, workspace: Workspace) => Promise<T | undefined>,
  serializeEntity: (entity: T) => unknown
): RefreshEntitiesAction<T> {
  return createAsyncThunk<
    Record<string, { entity: T; draft?: string } | null>,
    { wsId: string; entityIds: string[] },
    { state: RootState }
  >(`${sliceName}/refresh`, async ({ wsId, entityIds }, { getState }) => {
    const workspace = workspaceSelectors.selectById(getState(), wsId);
    assertWorkspace(workspace);

    const opened: Record<string, { entity: T; draft?: string } | null> = {};
    await Promise.all(
      entityIds.map(async (id) => {
        try {
          const entityFromFile = await getEntity(id, workspace);

          if (entityFromFile) {
            opened[id] = {
              entity: entityFromFile,
              draft: serializeEntity
                ? YAML.stringify(serializeEntity(entityFromFile))
                : undefined,
            };
          } else {
            opened[id] = null;
          }
        } catch (err) {
          opened[id] = null;
        }
      })
    );
    return opened;
  });
}

export type OpenEntityAction<T extends Entity> = AsyncThunk<
  T,
  string,
  { state: unknown }
>;

/**
 * Load entity and select it
 */
export function openEntityActionCreator<T extends Entity>(
  sliceName: string,
  getEntity: GetEntity<T>,
  actionType: "open" | "load" = "open"
): OpenEntityAction<T> {
  return createAsyncThunk<T, string, { state: RootState }>(
    `${sliceName}/${actionType}`,
    async (id, { getState }) => {
      const redoableSlice = getRedoableSlice(getState(), sliceName);
      let openedEntity = selectEntity(redoableSlice, id)?.present;

      if (!openedEntity) {
        const workspace = selectSelectedWorkspace(getState());
        assertWorkspace(workspace);

        openedEntity = await getEntity(id, workspace);
      }

      return openedEntity as T;
    }
  );
}

export type StoreEntityAction<T extends Entity> = AsyncThunk<
  T,
  { entity: T; targetPath?: string },
  { state: unknown }
>;

export function storeEntityActionCreator<T extends Entity>(
  sliceName: string,
  saveEntity: SaveEntity<T>,
  entityFolder: WorkspaceFolder
): StoreEntityAction<T> {
  return createAsyncThunk<
    T,
    { entity: T; targetPath?: string },
    { state: RootState }
  >(
    `${sliceName}/store`,
    async (
      { entity, targetPath: targetPathOverride },
      { getState, dispatch }
    ) => {
      const workspace = selectSelectedWorkspace(getState());
      assertWorkspace(workspace);

      const result = await saveEntity(
        entity,
        workspace,
        targetPathOverride ||
          getEntityTargetPath(getState, sliceName, entity.id)
      );
      // TODO: improve by updating only a single file
      await dispatch(updateWorkspaceFolderAction(entityFolder)).unwrap();

      return result;
    }
  );
}

export type MoveEntityAction<T extends Entity> = AsyncThunk<
  void,
  { id: T["id"]; targetPath: string },
  { state: unknown }
>;

export function moveEntityActionCreator<T extends Entity>(
  sliceName: string,
  moveEntity: MoveEntity<T>,
  entityFolder: WorkspaceFolder
): MoveEntityAction<T> {
  return createAsyncThunk<
    void,
    { id: T["id"]; targetPath: string },
    { state: RootState }
  >(`${sliceName}/move`, async ({ id, targetPath }, { getState, dispatch }) => {
    const workspace = selectSelectedWorkspace(getState());
    assertWorkspace(workspace);

    await moveEntity(id, workspace, targetPath);
    // TODO: improve by updating only 2 files - the old and the new one
    await dispatch(updateWorkspaceFolderAction(entityFolder)).unwrap();
  });
}

export type StoreFolderActionPayload = {
  folderId: string;
  subfolderName: string;
};
export type StoreFolderAction = AsyncThunk<
  string,
  StoreFolderActionPayload,
  { state: unknown }
>;

export function storeFolderActionCreator(
  sliceName: string,
  entityFolder: WorkspaceFolder
): StoreFolderAction {
  return createAsyncThunk<
    string,
    StoreFolderActionPayload,
    { state: RootState }
  >(
    `${sliceName}/storeFolder`,
    async ({ folderId, subfolderName }, { getState, dispatch }) => {
      const workspace = selectSelectedWorkspace(getState());
      assertWorkspace(workspace);

      const parentFolderPath = selectFolderTreeFolderNodePath(
        workspace[entityFolder],
        folderId
      );

      if (!parentFolderPath) throw new Error("Parent folder does not exist");

      await saveEntityFolder(
        workspace,
        entityFolder,
        posixPath.join(parentFolderPath, subfolderName)
      );
      await dispatch(updateWorkspaceFolderAction(entityFolder)).unwrap();

      return folderId;
    }
  );
}

type RenameFolderActionPayload = {
  folderId: string;
  newName: string;
};

function renameFolderActionCreatorHelper(
  sliceName: string,
  entityFolder: WorkspaceFolder,
  renameFolder: MoveFolder,
  action: "rename" | "move" | "overwrite"
) {
  return createAsyncThunk<
    void,
    RenameFolderActionPayload,
    { state: RootState }
  >(
    `${sliceName}/${action}Folder`,
    async ({ folderId, newName }, { getState, dispatch }) => {
      const workspace = selectSelectedWorkspace(getState());
      const settings = getState().settings;
      assertWorkspace(workspace);
      assertSettings(settings);

      const currentFolderName = getFileNameFromRegistry(folderId);

      if (!currentFolderName) {
        throw new Error("Folder was not found inside registry");
      }

      const { oldPath, newPath } = await renameFolder(
        currentFolderName,
        workspace,
        entityFolder,
        folderId,
        newName
      );

      await dispatch(updateWorkspaceFolderAction(entityFolder)).unwrap();
      await pushModifiedFolderToGit(
        workspace,
        entityFolder,
        oldPath,
        newPath,
        settings.repositoryUrl,
        settings.repositoryToken,
        settings.repositoryUsername
      );
    }
  );
}

export type RenameFolderAction = AsyncThunk<
  void,
  RenameFolderActionPayload,
  { state: unknown }
>;

export function renameFolderActionCreator(
  sliceName: string,
  entityFolder: WorkspaceFolder
): RenameFolderAction {
  return renameFolderActionCreatorHelper(
    sliceName,
    entityFolder,
    renameEntityFolder,
    "rename"
  );
}

export function moveFolderActionCreator(
  sliceName: string,
  entityFolder: WorkspaceFolder,
  type: "overwrite" | "move" = "move"
): RenameFolderAction {
  return renameFolderActionCreatorHelper(
    sliceName,
    entityFolder,
    moveEntityFolder,
    type
  );
}

export type DeleteEntityAction = AsyncThunk<void, string, { state: unknown }>;

export function deleteEntityActionCreator(
  sliceName: string,
  deleteEntity: DeleteEntity,
  entityFolder: WorkspaceFolder,
  action: "delete" | "deleteFolder" = "delete"
): DeleteEntityAction {
  return createAsyncThunk<void, string, { state: RootState }>(
    `${sliceName}/${action}`,
    async (id, { getState, dispatch }) => {
      const workspace = selectSelectedWorkspace(getState());
      assertWorkspace(workspace);

      await deleteEntity(id, workspace);

      // If file is not already hidden (i.e. during undoable delete operation) then hide it
      if (findNode(id, workspace[entityFolder]) !== undefined) {
        await dispatch(hideEntityFileFromWorkspaceAction({ id, entityFolder }));
      }
    }
  );
}

export type DeleteFolderAction = AsyncThunk<void, string, { state: unknown }>;

export function deleteFolderActionCreator(
  sliceName: string,
  entityFolder: WorkspaceFolder
): DeleteFolderAction {
  // TODO: Possible to create "isDir" flag inside references to remove need for separate action - code cannot accurately know whether ref is a folder
  return deleteEntityActionCreator(
    sliceName,
    (id, ws) => deleteEntityFolder(id, entityFolder, ws),
    entityFolder,
    "deleteFolder"
  );
}

export function addEntityToGitActionCreator(
  sliceName: string,
  entityFolder: WorkspaceFolder,
  customPushEntityChangesToGit?: PushChangesToGit
): AsyncThunk<void, { id: string; oldName?: string }, { state: unknown }> {
  return createAsyncThunk<
    void,
    { id: string; oldName?: string },
    { state: RootState }
  >(`${sliceName}/addToGit`, async ({ id, oldName }, { getState }) => {
    const workspace = selectSelectedWorkspace(getState());
    const settings = getState().settings;

    assertWorkspace(workspace);
    assertSettings(settings);
    const pushAction = customPushEntityChangesToGit ?? pushChangesToGit;
    await pushAction(
      id,
      workspace,
      entityFolder,
      settings.repositoryUrl,
      settings.repositoryToken,
      oldName,
      settings.repositoryUsername
    );
  });
}

export type StoreAndPushAction<T extends Entity> = AsyncThunk<
  void,
  T,
  { state: unknown }
>;

/**
 * Stores entity in FS, then pushes it to remote. Supports file renaming.
 * @param sliceName
 * @param nameSelector
 * @param storeEntityAction
 * @param addEntityToGitAction
 */
export function storeAndPushActionCreator<T extends Entity>(
  sliceName: string,
  nameSelector: (entity: T) => string,
  storeEntityAction: StoreEntityAction<T>,
  addEntityToGitAction: ReturnType<typeof addEntityToGitActionCreator>
): StoreAndPushAction<T> {
  return createAsyncThunk<void, T, { state: RootState }>(
    `${sliceName}/storeAndPush`,
    async (entity, { dispatch }) => {
      const { id } = entity;

      const isRenamed = isFileRenamed(id, nameSelector(entity));
      const oldFileName = getFileNameFromRegistry(id);

      await fsActionQueue.add(async () => {
        await dispatch(storeEntityAction({ entity })).unwrap();
        await dispatch(
          addEntityToGitAction({
            id,
            oldName: isRenamed ? oldFileName : undefined,
          })
        ).unwrap();
      });
    }
  );
}

export type MoveAndPushAction<T extends Entity> = AsyncThunk<
  void,
  { id: T["id"]; targetPath: string },
  { state: unknown }
>;

/**
 * Moves entity in FS, then pushes it to remote.
 * @param sliceName
 * @param moveEntityAction
 * @param addEntityToGitAction
 */
export function moveAndPushActionCreator<T extends Entity>(
  sliceName: string,
  moveEntityAction: MoveEntityAction<T>,
  addEntityToGitAction: ReturnType<typeof addEntityToGitActionCreator>
): MoveAndPushAction<T> {
  return createAsyncThunk<
    void,
    { id: T["id"]; targetPath: string },
    { state: RootState }
  >(`${sliceName}/moveAndPush`, async (moveEntityArgs, { dispatch }) => {
    const { id } = moveEntityArgs;
    const oldName = getFileNameFromRegistry(id);

    await fsActionQueue.add(async () => {
      await dispatch(moveEntityAction(moveEntityArgs)).unwrap();
      await dispatch(
        addEntityToGitAction({
          id,
          oldName,
        })
      ).unwrap();
    });
  });
}

/**
 * Deletes entity from FS, then pushes it to remote. Includes handling of renamed files.
 * @param sliceName
 * @param deleteEntityAction
 * @param addEntityToGitAction
 */
export function deleteAndPushActionCreator(
  sliceName: string,
  deleteEntityAction: DeleteEntityAction,
  addEntityToGitAction: ReturnType<typeof addEntityToGitActionCreator>
): AsyncThunk<void, string, { state: unknown }> {
  return createAsyncThunk<void, string, { state: RootState }>(
    `${sliceName}/deleteAndPush`,
    async (id, { dispatch }) => {
      await fsActionQueue.add(async () => {
        await dispatch(deleteEntityAction(id)).unwrap();
        await dispatch(
          addEntityToGitAction({
            id,
          })
        ).unwrap();
      });
    }
  );
}
