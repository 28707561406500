export function asBearer(token: string): string {
  return `Bearer ${token}`;
}

export function omitBearer(token: string | undefined): string {
  if (!token) {
    return "";
  }
  return token.toLowerCase().startsWith("bearer ")
    ? token.slice("bearer ".length)
    : token;
}
