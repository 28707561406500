import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import { Unzipped, unzipSync } from "fflate";

import { writeFileDeep } from "./fs-utils";

const DEFAULT_UNZIP_TIMEOUT_MS = 300000;

export const ERR_MSG_CANNOT_READ_FILE =
  "Could not read selected file. Please verify it is a valid zip file and not corrupted.";
export const ERR_MSG_TIMEOUT = "Extraction of the zip file timed out.";

export async function extractZipToFolder(
  targetLocation: string,
  buffer: Buffer,
  msTimeout?: number
): Promise<void> {
  await timeoutPromise(
    extractBuffer(buffer, async (fileName: string, fileBuffer: Uint8Array) => {
      await writeFileDeep(posixPath.join(targetLocation, fileName), fileBuffer);
    }),
    msTimeout || DEFAULT_UNZIP_TIMEOUT_MS
  );
}

async function extractBuffer(
  buffer: Buffer,
  onFile: (fileName: string, buffer: Uint8Array) => Promise<void>
): Promise<void> {
  let unzipped: Unzipped;

  try {
    unzipped = unzipSync(buffer);
  } catch (e) {
    throw ERR_MSG_CANNOT_READ_FILE;
  }

  for (const [relativePath, content] of Object.entries(unzipped)) {
    if (isDirectory(relativePath)) {
      continue;
    }

    await onFile(relativePath, content);
  }
}

function isDirectory(somePath: string): boolean {
  return somePath.endsWith("/") || somePath.endsWith("\\");
}

async function timeoutPromise<T>(
  promise: Promise<T>,
  msTimeout: number
): Promise<T | undefined> {
  const timeoutPromise: Promise<undefined> = new Promise((_, reject) => {
    setTimeout(() => {
      reject(ERR_MSG_TIMEOUT);
    }, msTimeout);
  });

  return await Promise.race([promise, timeoutPromise]);
}
