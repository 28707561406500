import { OpenAPIV3 } from "openapi-types";
import { AceApiOperation } from "../model";

export const getApiOperationId = (
  path: string,
  method: string,
  apiId: string
): string => `${apiId}#${path}#${method}`;

export const convertApiOperationToAceApiOperation = (
  operation: OpenAPIV3.OperationObject,
  apiId: string,
  path: string,
  method: OpenAPIV3.HttpMethods
): AceApiOperation => {
  const id = getApiOperationId(path, method, apiId);
  return { path, verb: method, id, ...operation };
};

const getOperationsFromEndpoint = (
  endpoint: OpenAPIV3.PathItemObject,
  path: string,
  apiId: string
) =>
  Object.values(OpenAPIV3.HttpMethods).reduce<AceApiOperation[]>(
    (operations, method) => {
      const operation = endpoint[method];
      if (!operation) return operations;

      const apiOperation = convertApiOperationToAceApiOperation(
        operation,
        apiId,
        path,
        method
      );

      return [...operations, apiOperation];
    },
    []
  );

export const getApiOperationList = (
  paths: OpenAPIV3.PathsObject,
  apiId: string
): AceApiOperation[] =>
  Object.entries(paths).reduce<AceApiOperation[]>(
    (operations, [path, endpoint]) =>
      endpoint
        ? [...operations, ...getOperationsFromEndpoint(endpoint, path, apiId)]
        : operations,
    []
  );
