import _ from "lodash";
import isString from "lodash/isString";
import YAML from "yaml";

export function isPlainObject(
  value: unknown
): value is Record<string, unknown> {
  return _.isPlainObject(value);
}

export function isArray(value: unknown): value is Array<unknown> {
  return Array.isArray(value);
}

export function safeFromYaml(txt: string): unknown | undefined {
  try {
    return YAML.parse(txt);
  } catch (_) {
    return undefined;
  }
}

export function safeToYaml(yml: unknown): string {
  try {
    return YAML.stringify(yml);
  } catch (_) {
    return "";
  }
}

export function safeToJson(json?: string): Record<string, unknown> {
  try {
    if (json === undefined) {
      return {};
    }
    return JSON.parse(json);
  } catch {
    return {};
  }
}

export function safeToString(json: unknown): string {
  try {
    const out = JSON.stringify(json);
    return isString(out) ? out : "null";
  } catch {
    return "null";
  }
}

export interface ParseResult<T> {
  content: T;
  isValid: boolean;
}

export function safeParseYaml(yaml: string): ParseResult<unknown> {
  // YAML library does not throw on stringify, only on parse
  try {
    return {
      content: YAML.parse(yaml),
      isValid: true,
    };
  } catch (e) {
    return { content: {}, isValid: false };
  }
}

export function mapPrimitiveValuesOfObject(
  obj: Record<string, unknown>,
  onValue: (val: unknown) => unknown
): Record<string, unknown> {
  const res = JSON.stringify(obj, (_, val) =>
    typeof val === "object" ? val : onValue(val)
  );
  return JSON.parse(res);
}

export function toNumberWithDefault(
  value: string | number | undefined,
  defaultValue: number
): number {
  if (value === undefined || value === "") {
    return defaultValue;
  }
  const parsedValue = Number(value);
  return isNaN(parsedValue) ? defaultValue : parsedValue;
}

export function toNumber(
  value: string | number | undefined
): number | undefined {
  if (value === undefined || value === "") {
    return undefined;
  }
  const parsedValue = Number(value);
  return isNaN(parsedValue) ? undefined : parsedValue;
}

export function toBooleanWithDefault(
  value: string | boolean | undefined,
  defaultValue: boolean
): boolean {
  if (value === undefined || value === "") {
    return defaultValue;
  }
  if (typeof value === "boolean") {
    return value;
  }
  if (value.toLowerCase() === "true") {
    return true;
  } else if (value.toLowerCase() === "false") {
    return false;
  }
  return defaultValue;
}

export function toBoolean(value: string | boolean | undefined): boolean {
  return toBooleanWithDefault(value, false);
}
