import get from "lodash/get";
import { ApiOperationMediaType, SUPPORTED_MEDIA_TYPES } from "model";
import { OpenAPIV3 } from "openapi-types";

export const getOperationReqBodyFirstSupportedMediaType = (
  operation: Partial<OpenAPIV3.OperationObject>
): ApiOperationMediaType => {
  const mediaType = getFirstSupportedMediaType(
    Object.keys(get(operation, "requestBody.content", {}))
  );
  return mediaType;
};

export const getFirstSupportedMediaType = (
  mediaTypes: string[]
): ApiOperationMediaType => {
  const supportedMediaType =
    SUPPORTED_MEDIA_TYPES.find((type) => mediaTypes.includes(type)) ??
    SUPPORTED_MEDIA_TYPES[0];

  return supportedMediaType;
};
