import {
  createSlice,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from "@reduxjs/toolkit";
import { v4 } from "uuid";

import { Designer, Pages, TabBaseUiConfig } from "../../model";
import { FieldError } from "../../model/formError";
import { NotificationOptionalKey } from "../../model/notification";
import {
  generateDataForInputSchemaAction,
  testFlowAction,
  testStepAction,
} from "../flows/actions";
import { testSchedulerAction } from "../schedules/actions";
import { initializeVariablesAction } from "../variables/initializeVariablesAction";
import { indexWorkspaceReferencesAction } from "../workspaces/actions";
import {
  moveWorkspaceToAnotherBranchAction,
  resetRemoteToWorkspaceAction,
  resetWorkspaceToRemoteAction,
} from "../workspaces/conflictResolutionActions";

import { setPageProperty } from "./caseReducers/utils/setPageProperty";
import {
  addNotification,
  getACEVersionAction,
  hideLocalRepositoryWarning,
  setWorkspaceHasConflictsFlagAction,
  showLocalRepositoryWarning,
} from "./actions";
import { initialState } from "./initialState";
import { resetAllOpenFlowsData, setUiData } from "./mutator";
import { pageCaseReducers } from "./pageCaseReducers";

const uiTabLoaderInitState = {
  loading: false,
};

const isWorkspaceConflictResolutionFulfilled = isFulfilled(
  resetWorkspaceToRemoteAction,
  resetRemoteToWorkspaceAction,
  moveWorkspaceToAnotherBranchAction
);

const slice = createSlice({
  name: "designer",
  initialState: initialState as Designer,
  reducers: {
    ...pageCaseReducers,
    // === Custom page case reducers ===
    resetDesignerPages(state) {
      state.pages = initialState.pages;
    },
    setFlowView(state, action: PayloadAction<Pages["flow"]["view"]>) {
      setPageProperty(state, "flow", "view", action.payload);
    },
    setApiShowInfoAlert(
      state,
      action: PayloadAction<Pages["api"]["showInfoAlert"]>
    ) {
      setPageProperty(state, "api", "showInfoAlert", action.payload);
    },
    // === Root case reducers ===
    openSettings: (state) => {
      state.settingsOpen = true;
    },
    closeSettings: (state) => {
      state.settingsOpen = false;
    },
    openWorkspaceManager: (state) => {
      state.workspaceManagerOpen = true;
    },
    closeWorkspaceManager: (state) => {
      state.workspaceManagerOpen = false;
    },
    setDesignerLoading: (state, action: PayloadAction<boolean>) => {
      state.isDesignerLoading = action.payload;
    },
    selectStep(
      state,
      action: PayloadAction<{ flowId: string; stepId: string }>
    ) {
      const { stepId, flowId } = action.payload;
      state.selectedStepIds[flowId] = stepId;
    },
    deselectStep(state, action: PayloadAction<string>) {
      delete state.selectedStepIds[action.payload];
    },
    removeFlowDebugData(state, action: PayloadAction<string>) {
      delete state.flowUi[action.payload];
    },
    removeStepDebugData(state, action: PayloadAction<string>) {
      for (const key in state.stepUi) {
        if (state.stepUi[key].flowId === action.payload) {
          delete state.stepUi[key];
        }
      }
    },
    removeNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        (n) => n.key !== action.payload
      );
    },
    setStepInputData(
      state,
      action: PayloadAction<{
        stepId: string;
        flowId: string;
        inputData?: unknown;
        inputHeaders?: unknown;
      }>
    ) {
      const { inputData, inputHeaders, stepId, flowId } = action.payload;
      state.stepUi[stepId] = {
        ...state.stepUi[stepId],
        flowId: flowId,
        inputData: inputData || state.stepUi[stepId]?.inputData || {},
        inputHeaders: inputHeaders || state.stepUi[stepId]?.inputHeaders || {},
      };
    },
    setUiDataForTab(
      state,
      action: PayloadAction<{
        stepId?: string;
        flowId?: string;
        schedulerId?: string;
        data: Partial<TabBaseUiConfig>;
      }>
    ) {
      setUiData(state, action.payload, action.payload.data);
    },
    setEntityValidationErrors(
      state,
      action: PayloadAction<{ id: string; errors: Array<FieldError> }>
    ) {
      const { id, errors } = action.payload;
      state.entityValidationErrors[id] = errors;
    },
    clearEntityValidationErrors(state, action: PayloadAction<string>) {
      delete state.entityValidationErrors[action.payload];
    },
    showFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      state.flowsSourceViewEnabled[flowId] = true;
    },
    hideFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      state.flowsSourceViewEnabled[flowId] = false;
    },
    removeFlowSourceView(state, action: PayloadAction<string>) {
      const flowId = action.payload;
      delete state.flowsSourceViewEnabled[flowId];
    },
    setUsername(state, action: PayloadAction<string | null>) {
      state.username = action.payload;
    },
    resetAllFlowsData(state, action: PayloadAction<string | undefined>) {
      resetAllOpenFlowsData(state, action.payload);
    },
    toggleStepCollapse(state, action: PayloadAction<string>) {
      const stepId = action.payload;

      if (!state.pages.flow.collapsedSteps[stepId]) {
        state.pages.flow.collapsedSteps[stepId] = true;
      } else {
        delete state.pages.flow.collapsedSteps[stepId];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getACEVersionAction.fulfilled, (state, action) => {
        state.version = action.payload;
      })
      .addCase(setWorkspaceHasConflictsFlagAction, (state) => {
        state.hasWorkspaceConflicts = true;
      })
      .addCase(showLocalRepositoryWarning, (state) => {
        state.localRepositoryWarning = true;
      })
      .addCase(hideLocalRepositoryWarning, (state) => {
        state.localRepositoryWarning = false;
      })
      .addCase(indexWorkspaceReferencesAction.pending, (state) => {
        state.isIndexing = true;
      })
      .addCase(indexWorkspaceReferencesAction.fulfilled, (state) => {
        state.isIndexing = false;
      })
      .addCase(initializeVariablesAction.fulfilled, (state, action) => {
        pageCaseReducers.selectVariablesTreeItemId(state, action);
      })
      .addCase(
        addNotification,
        (state, action: PayloadAction<NotificationOptionalKey>) => {
          const key = action.payload.key ?? v4();
          const isUnique = !state.notifications.find((n) => n.key === key);

          if (isUnique) {
            state.notifications.push({ ...action.payload, key });
          }
        }
      )
      .addCase(generateDataForInputSchemaAction.fulfilled, (state, action) => {
        const { sampleData, id } = action.payload;
        state.flowUi[id] = {
          ...state.flowUi[id],
          inputData: sampleData ?? {},
          outputData: {},
        };
      })
      .addMatcher(isWorkspaceConflictResolutionFulfilled, (state) => {
        state.hasWorkspaceConflicts = false;
      })
      .addMatcher(
        isAnyOf(isPending(testFlowAction, testStepAction, testSchedulerAction)),
        (state, action) => {
          setUiData(state, action.meta.arg, { loading: true });
        }
      )
      .addMatcher(
        isAnyOf(
          isFulfilled(testFlowAction, testStepAction, testSchedulerAction)
        ),
        (state, action) => {
          setUiData(state, action.meta.arg, { loading: false });
        }
      )
      .addMatcher(
        isAnyOf(
          isRejected(testSchedulerAction, testFlowAction, testStepAction)
        ),
        (state, action) => {
          setUiData(state, action.meta.arg, uiTabLoaderInitState);
        }
      )
      .addMatcher(
        isFulfilled(testSchedulerAction, testFlowAction, testStepAction),
        (state, action) => {
          setUiData(state, action.meta.arg, {
            outputData: action.payload.result,
            ...uiTabLoaderInitState,
          });
        }
      );
  },
});

export default slice.reducer;

export const {
  openSettings,
  closeSettings,
  openWorkspaceManager,
  closeWorkspaceManager,
  setDesignerLoading,
  removeNotification,
  setEntityValidationErrors,
  clearEntityValidationErrors,
  setUiDataForTab,
  setUsername,
  resetDesignerPages,
} = slice.actions;

// TODO: move under designer > pages > flow
export const {
  showFlowSourceView,
  hideFlowSourceView,
  removeFlowSourceView,
  removeFlowDebugData,
  resetAllFlowsData,
  toggleStepCollapse,
} = slice.actions;

// TODO: move under designer > pages > flow (> steps)?
export const {
  selectStep,
  deselectStep,
  setStepInputData,
  removeStepDebugData,
} = slice.actions;

// TODO: solve
// Keep here, otherwise breaks tests due to `InitializeApisAction`
export const { selectApiTreeItemId: selectApiFile } = slice.actions;

export const designerPageDataActions = slice.actions;
