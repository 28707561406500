import { createSlice, isAnyOf, isFulfilled } from "@reduxjs/toolkit";
import { UserSettings } from "@sapiens-digital/ace-designer-common";
import { SettingsManager } from "services/settingsManager";

import {
  removeGitAuthorFromKeycloakAction,
  saveGitAuthorFromKeycloakAction,
  selectWorkspaceAction,
  updateSettingsAction,
} from "./actions";

const settingsSlice = createSlice({
  name: "settings",
  initialState: {} as UserSettings,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(selectWorkspaceAction, (state, action) => {
        state.selectedWorkspaceId = action.payload;
      })
      .addMatcher(
        isAnyOf(
          isFulfilled(
            saveGitAuthorFromKeycloakAction,
            removeGitAuthorFromKeycloakAction
          ),
          updateSettingsAction
        ),
        (_state, action) => ({
          ...action.payload,
          repositoryToken: SettingsManager.getDesignerConfigs()
            .featurePersistentSettings
            ? ""
            : action.payload.repositoryToken,
        })
      );
  },
});

export default settingsSlice.reducer;
