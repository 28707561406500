import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const fileReferenceStepSchema: StepSchema = {
  stepType: Steps.FILE_REFERENCE,
  color: "rgb(247,225,211)",
  jsonSchema: {
    description: "File reference step definition",
    title: "File Reference",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
      },
      description: {
        type: "string",
        title: "Step description",
      },
      condition: {
        type: "string",
        title: "Condition",
        description:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["action"],
        properties: {
          action: {
            type: "string",
            title: "Action",
            description:
              "File reference action - read from file or write to file.",
            enum: ["read", "write"],
            default: "read",
          },
        },
        dependencies: {
          action: {
            oneOf: [
              {
                required: ["documentPath", "targetPath", "typeOfFile"],
                properties: {
                  action: {
                    enum: ["write"],
                  },
                  typeOfFile: {
                    type: "string",
                    title: "File type",
                    enum: ["text", "binary"],
                    default: "text",
                  },
                  documentPath: {
                    type: "string",
                    title: "Document path",
                    description:
                      "Path to document node which contains file content",
                  },
                  targetPath: {
                    type: "string",
                    title: "Target path",
                    description:
                      "Path to document node which will contain file reference",
                  },
                  fileName: {
                    type: "string",
                    title: "Display file name",
                  },
                  mimeType: {
                    type: "string",
                    title: "Mime type",
                  },
                },
                dependencies: {
                  typeOfFile: {
                    oneOf: [
                      {
                        properties: {
                          typeOfFile: {
                            enum: ["text"],
                          },
                          encoding: {
                            type: "string",
                            title: "File encoding",
                            enum: ["utf8", "utf16le", "latin1"],
                            description: "Node.js encoding for writing a file",
                            default: "utf8",
                          },
                        },
                      },
                      {
                        properties: {
                          typeOfFile: {
                            enum: ["binary"],
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                required: ["targetPath", "fileRefPath", "typeOfFile"],
                properties: {
                  action: {
                    enum: ["read"],
                  },
                  typeOfFile: {
                    type: "string",
                    title: "File type",
                    enum: ["text", "binary"],
                    default: "text",
                  },
                  fileRefPath: {
                    type: "string",
                    title: "File reference path",
                  },
                  targetPath: {
                    type: "string",
                    title: "Target path",
                    description:
                      "Path to document node which will contain file content",
                  },
                },
                dependencies: {
                  typeOfFile: {
                    oneOf: [
                      {
                        properties: {
                          typeOfFile: {
                            enum: ["text"],
                          },
                          encoding: {
                            type: "string",
                            title: "File encoding",
                            description: "Node.js encoding for reading a file",
                            enum: ["utf8", "utf16le", "latin1"],
                            default: "utf8",
                          },
                        },
                      },
                      {
                        properties: {
                          typeOfFile: {
                            enum: ["binary"],
                          },
                        },
                      },
                    ],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      "ui:order": [
        "doc",
        "action",
        "documentPath",
        "fileRefPath",
        "typeOfFile",
        "targetPath",
        "*",
      ],
    },
  },
};
