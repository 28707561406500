import { Designer } from "../../../../model";
import { toggle } from "../../../utils/toggle";
import { PagesWithFileTrees } from "../FileTreeCaseReducers";

import { setPageProperty } from "./setPageProperty";

export function toggleExpandedFolder<TPage extends PagesWithFileTrees>(
  state: Designer,
  page: TPage,
  folderId: string
): void {
  setPageProperty(
    state,
    page,
    "expandedFolderIds",
    toggle(state.pages[page].expandedFolderIds, folderId)
  );
}
