import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { APIDefinition } from "@sapiens-digital/ace-designer-app/app/components/api/api-definition/APIDefinition";
import { Designer } from "@sapiens-digital/ace-designer-app/app/components/Designer";
import { notifyError } from "@sapiens-digital/ace-designer-app/app/components/utils/notify";
import { useAppDispatch } from "@sapiens-digital/ace-designer-app/app/store";
import {
  initializeDesignerAction,
  loginDesignerThunk,
} from "@sapiens-digital/ace-designer-app/app/store/designer/actions";

import logo from "./assets/logo.png";
import LoginPopup from "./login/LoginPopup";
import LoginTracker from "./login/LoginTracker";

function DesignerApp(): React.ReactElement {
  const [isInitialized, setIsInitialized] = useState(false);
  const dispatch = useAppDispatch();

  const initDesigner = async () => {
    if (isInitialized) {
      const result = await dispatch(loginDesignerThunk());
      notifyError(result, dispatch);
    } else {
      await dispatch(initializeDesignerAction()).unwrap();
      setIsInitialized(true);
    }
  };

  return (
    <React.Fragment>
      <LoginTracker />
      <LoginPopup onLogin={initDesigner} />
      <Routes>
        <Route path="/api-definition" element={<APIDefinition />} />
        <Route path="/*" element={<Designer logo={logo} />} />
      </Routes>
    </React.Fragment>
  );
}

export default DesignerApp;
