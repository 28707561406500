import { SettingsManager } from "services/settingsManager";

import { Flow, UserSettings, ValidatedSettings } from "../../model";
import { Workspace } from "../../model/workspace";
import { Environment, getEnvironment } from "../../services/workspace";

export function assertFlow(flow: Flow | undefined): asserts flow is Flow {
  if (!flow) throw new Error("Flow could not be found");
}

export function assertWorkspace(
  workspace: Workspace | undefined,
  workspaceId?: string
): asserts workspace is Workspace {
  if (!workspace) {
    const message = workspaceId
      ? `Workspace with id="${workspaceId}" does not exist`
      : "Workspace is not selected";
    throw new Error(message);
  }
}

export function assertSettings(
  settings: Partial<UserSettings>
): asserts settings is ValidatedSettings {
  if (settings.repositoryUrl === undefined) {
    throw new Error(`Repository URL is not defined`);
  }

  if (settings.repositoryToken === undefined) {
    throw new Error(`Repository token is not defined`);
  }
}

export function assertWebSettings(): void {
  const isWeb = getEnvironment() === Environment.Web;

  if (isWeb && !SettingsManager.getDesignerConfigs().publicApiBaseUrl) {
    throw new Error(`Development Server Url not defined`);
  }
}
