import React, { FC, Suspense } from "react";
import { DndProvider } from "react-dnd";
import { Navigate, Route, Routes } from "react-router-dom";
import { getBackendOptions, MultiBackend } from "@minoru/react-dnd-treeview";
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { Provider as InversifyProvider } from "inversify-react";
import { SnackbarProvider } from "notistack";

import { container } from "../services";

import WorkspaceManager from "./workspace/WorkspaceManager";
import { aceTheme } from "./ace-theme";
import ConflictResolutionDialog from "./ConflictResolutionDialog";
import DesignerSettings from "./DesignerSettings";
import ErrorBoundary from "./ErrorBoundary";
import LayoutLoader from "./LayoutLoader";
import MainLayout from "./MainLayout";
import NotificationProvider, {
  NotificationProviderProps,
} from "./NotificationProvider";
import { useRouteAdjuster } from "./useRouteAdjuster";

export const FLOWS_PATH = "flows";
export const FLOW_EDITOR_PATH = "edit";
export const SCHEDULER_PATH = "scheduler";
export const VIRTUAL_STEP_PATH = "virtual-steps";
export const APIS_PATH = "dynamic-apis";
export const SCHEMAS_PATH = "schemas";
export const FLOWS_PATHNAME = `/${FLOWS_PATH}`;
export const SCHEMAS_PATHNAME = `/${SCHEMAS_PATH}`;
export const APIS_PATHNAME = `/${APIS_PATH}`;
export const ENV_VARS_PATH = "workspace-variables";
export const ERR_HANDLERS_PATH = "error-handlers";

const FlowView = React.lazy(() => import("../components/flow/Flows"));
const DynamicApisView = React.lazy(() => import("../components/api/APIs"));
const SchedulerView = React.lazy(
  () => import("../components/scheduler/Scheduler")
);
const SchemaView = React.lazy(() => import("../components/schema/Schema"));

const VirtualStepsView = React.lazy(
  () => import("./virtual-step/VirtualSteps")
);

const ErrorHandlerView = React.lazy(
  () => import("../components/error-handler/ErrorHandler")
);
const EnvVariable = React.lazy(() => import("./variables/EnvVariable"));

export interface DesignerProvidersProps {
  theme?: Theme;
}

export interface DesignerProps extends DesignerProvidersProps {
  logo: string;
}

const MAX_NOTIFICATIONS = 5;

export const WithInversifyProvider: FC = ({ children }) => (
  <InversifyProvider container={container}>{children}</InversifyProvider>
);

export const WithStyleProvider: FC<DesignerProvidersProps> = ({
  theme,
  children,
}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme ? theme : aceTheme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);

export const WithNotificationProvider: FC<NotificationProviderProps> = ({
  children,
  TransitionComponent,
}) => (
  <NotificationProvider TransitionComponent={TransitionComponent}>
    <SnackbarProvider maxSnack={MAX_NOTIFICATIONS}>{children}</SnackbarProvider>
  </NotificationProvider>
);

export const WithDndProvider: FC = ({ children }) => (
  <DndProvider backend={MultiBackend} options={getBackendOptions()}>
    {children}
  </DndProvider>
);

export const Designer: FC<DesignerProps> = (props: DesignerProps) => {
  useRouteAdjuster();
  return (
    <WithInversifyProvider>
      <WithStyleProvider {...props}>
        <WithNotificationProvider>
          <CssBaseline />
          <DesignerSettings />
          <WorkspaceManager />
          <ConflictResolutionDialog />
          <MainLayout logo={props.logo}>
            <Suspense fallback={<LayoutLoader />}>
              <Routes>
                <Route
                  path={`/${FLOWS_PATH}/*`}
                  element={
                    <ErrorBoundary>
                      <WithDndProvider>
                        <FlowView />
                      </WithDndProvider>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={`/${APIS_PATH}`}
                  element={
                    <ErrorBoundary>
                      <WithDndProvider>
                        <DynamicApisView />
                      </WithDndProvider>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={`/${SCHEDULER_PATH}`}
                  element={
                    <ErrorBoundary>
                      <SchedulerView />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={`/${SCHEMAS_PATH}`}
                  element={
                    <ErrorBoundary>
                      <WithDndProvider>
                        <SchemaView />
                      </WithDndProvider>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={`/${VIRTUAL_STEP_PATH}`}
                  element={
                    <ErrorBoundary>
                      <WithDndProvider>
                        <VirtualStepsView />
                      </WithDndProvider>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path={`/${ERR_HANDLERS_PATH}`}
                  element={
                    <ErrorBoundary>
                      <ErrorHandlerView />
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="/"
                  element={<Navigate replace to={`/${FLOWS_PATH}`} />}
                />
                <Route
                  path={`/${ENV_VARS_PATH}`}
                  element={
                    <ErrorBoundary>
                      <EnvVariable />
                    </ErrorBoundary>
                  }
                />
              </Routes>
            </Suspense>
          </MainLayout>
        </WithNotificationProvider>
      </WithStyleProvider>
    </WithInversifyProvider>
  );
};

export default Designer;
