import { OpenAPIV3 } from "openapi-types";

import { AceApiOperation, Api } from "../../model";

export function removeApiOperation(
  api: Api,
  path: string,
  method: OpenAPIV3.HttpMethods
): void {
  if (api.paths[path]) {
    delete api.paths[path]![method];

    if (Object.values(api.paths[path]!).length === 0) {
      delete api.paths[path];
    }
  }
}

export function upsertApiOperation(
  api: Api,
  operation: AceApiOperation,
  oldOperation?: AceApiOperation
): void {
  const { path, verb, id, ...rest } = operation;

  if (!api.paths[path]) {
    api.paths[path] = {};
  }

  api.paths[path]![verb] = rest;

  if (oldOperation && oldOperation.id !== id) {
    const { path: oldPath, verb: oldVerb } = oldOperation;
    removeApiOperation(api, oldPath, oldVerb);
  }
}
