import { WORKSPACE_PATHS } from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import get from "lodash/get";
import set from "lodash/set";
import { createSortYamlMapEntries } from "utils/sortYaml";

import { YAMLStringifyOptions } from "../../fs-utils";

import { V1EntityMigrator } from "./migrate";
import { extractFileName, saveAsYaml } from "./migrateUtils";

const SCHEDULER_KEYS = [
  "flowName",
  "flowBody",
  "pattern",
  "timezone",
  "isEnabled",
];

const yamlOptions: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(SCHEDULER_KEYS),
};

const migrateScheduler: V1EntityMigrator = async ({
  targetRepositoryLocation,
  fileBasename,
  content,
}) => {
  const migration = {};
  const name = extractFileName(content, fileBasename);
  set(migration, "name", name);
  set(migration, "flowName", get(content, "flowName"));
  set(migration, "pattern", get(content, "pattern"));
  set(migration, "timezone", get(content, "timezone"));
  let status = get(content, "status");
  status = convertStatus(status as string);
  set(migration, "isEnabled", status);
  set(migration, "flowBody", get(content, "flowBody"));
  await saveAsYaml(
    targetRepositoryLocation,
    WORKSPACE_PATHS.SCHEDULES,
    name,
    migration,
    yamlOptions
  );
  return [];
};

const convertStatus = (status: string): boolean =>
  status === "true" ? true : false;

export default migrateScheduler;
