import {
  SerializedEnvVariable,
  SerializedEnvVariables,
  WORKSPACE_PATHS,
} from "../../model";
import { isArray, isPlainObject, safeFromYaml, safeToYaml } from "../parsing";
import { LogFn, MergeRule } from "./merge";

export const mergeEnvs: MergeRule = {
  fileApplies: isEnvPathParts,
  modify: mergeEnvFiles,
};

export function isEnvPathParts(relativePathParts: string[]): boolean {
  const pathsDepth = 2;
  return (
    relativePathParts.length === pathsDepth &&
    relativePathParts[0] === WORKSPACE_PATHS.VARIABLES &&
    relativePathParts[1].endsWith(".yaml")
  );
}

export function mergeEnvFiles(
  newContent: string,
  existingContent: string,
  log: LogFn
): string {
  const newEnvs = asValidEnvs(newContent);
  const existingEnvs = asValidEnvs(existingContent);

  if (newEnvs && existingEnvs) {
    return safeToYaml(mergeVariablesContent(existingEnvs, newEnvs));
  }

  if (!existingEnvs && newEnvs) {
    log("can't merge invalid target document, overwriting");
    return newContent;
  }

  log("can't merge invalid source document, skipping");
  return existingContent;
}

export function mergeVariablesContent(
  varsOld: SerializedEnvVariables,
  varsNew: SerializedEnvVariables,
  log: LogFn = () => void {}
): SerializedEnvVariables {
  const newVariables: Array<SerializedEnvVariable> = [...varsOld.variables];

  varsNew.variables.forEach((v) => {
    const exsIdx = newVariables.findIndex((exsV) => exsV.name === v.name);
    if (exsIdx > -1) {
      log(`copying env '${v.name}'`);
      newVariables[exsIdx] = v;
    } else {
      newVariables.push(v);
    }
  });

  return { variables: newVariables };
}

function asValidEnvs(content: string): SerializedEnvVariables | undefined {
  const parsed = safeFromYaml(content);
  if (isSerializedEnvVars(parsed)) {
    return parsed;
  }

  return undefined;
}

function isSerializedEnvVars(vars: unknown): vars is SerializedEnvVariables {
  return (
    isPlainObject(vars) &&
    isArray(vars.variables) &&
    vars.variables.every(
      (v): v is SerializedEnvVariable =>
        isPlainObject(v) &&
        [v.name, v.value].every((f) => typeof f === "string")
    )
  );
}
