/**
 * These are the constants used mainly in UI components but also used in services, store, etc
 */

export const FORCED_VERSION_DISABLED = -1;
export const UN_VERSIONED_ENTITY_VAL = -1;

export const DEFAULT_GIT_AUTHOR = {
  name: "ACE",
  email: "ace@sapiens.com",
};
