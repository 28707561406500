import * as fsUtil from "@sapiens-digital/ace-designer-common";
import { Fs, wrapFsUtil } from "@sapiens-digital/ace-designer-common";
import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import YAML from "yaml";

import { Workspace, WorkspaceFolder } from "../model/workspace";

import { findNode } from "./nodes";
import {
  serializeId,
  upsertDeserializedFolderId,
  upsertDeserializedId,
} from "./references";
import {
  getContentRoot,
  getDirPath,
  getFolderPath,
  getWorkspaceFS,
} from "./workspace";

// Re-export commons fs utils
const getFs = () => getWorkspaceFS() as Fs;
export const isDir = wrapFsUtil(fsUtil.isDir, getFs);
export const mkdir = wrapFsUtil(fsUtil.mkdir, getFs);
export const exists = wrapFsUtil(fsUtil.exists, getFs);
export const safeRead = wrapFsUtil(fsUtil.safeRead, getFs);
export const safeWrite = wrapFsUtil(fsUtil.safeWrite, getFs);
export const copyFilesDeep = wrapFsUtil(fsUtil.copyFilesDeep, getFs);

async function deleteFileByName(
  fileName: string,
  workspace: Workspace,
  folder: WorkspaceFolder
): Promise<void> {
  const dir = getDirPath(workspace, folder);
  const absPath = posixPath.join(dir, fileName);

  const fs = getWorkspaceFS();
  return await fs.promises.unlink(absPath);
}

export const deleteFile = async (
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder
): Promise<void> => {
  const fileName = serializeId(id);
  if (!fileName) throw new Error("File name not found inside registry");
  // TODO: consider cleaning registry after flow was deleted
  return await deleteFileByName(fileName, workspace, folder);
};

export const deleteFolder = async (
  folderId: string,
  workspace: Workspace,
  folder: WorkspaceFolder
): Promise<void> => {
  const folderName = serializeId(folderId);

  if (!folderName) throw new Error("Folder was not found inside registry");

  return await rmdirDeep(
    posixPath.join(getDirPath(workspace, folder), folderName)
  );
};

function appendFileExtension(name: string) {
  const isNameWithExtension = posixPath.extname(name) === ".yaml";
  return isNameWithExtension ? name : `${name}.yaml`;
}

/**
 * Write file
 * @param fileName full file name with directories and an extension
 * @param workspace
 * @param folder
 * @param content
 */
async function writeFile(
  fileName: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  content: unknown
) {
  const dir = getDirPath(workspace, folder);
  const absPath = posixPath.join(dir, fileName);
  await writeYaml(absPath, content);
}

/**
 * Creates a new file
 * @param id
 * @param workspace
 * @param folder
 * @param newFileName including folders and a file extension
 * @param content
 */
async function createFile(
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  newFileName: string,
  content: unknown
) {
  if (!newFileName)
    throw new Error("Unable to create a file: new name was not provided");

  upsertDeserializedId(id, getContentRoot(workspace, folder), newFileName);
  await writeFile(newFileName, workspace, folder, content);
}

async function moveFileByPath(
  newPath: string,
  oldPath: string,
  folder: WorkspaceFolder,
  workspace: Workspace
) {
  const content = await readFileByName(workspace, folder, oldPath);
  await writeFile(newPath, workspace, folder, content);
  await deleteFileByName(oldPath, workspace, folder);
}

export const getFileNameFromRegistry = (id: string): string | undefined =>
  serializeId(id);

export const areFileNamesEqual = (n1: string, n2: string): boolean =>
  posixPath.basename(n1, ".yaml") === posixPath.basename(n2, ".yaml");

export const isFileRenamed = (
  id: string,
  currentName: string | undefined
): boolean => {
  const nameInRegistry = serializeId(id);
  if (!nameInRegistry || !currentName) return false;

  const sanitizedName = appendFileExtension(currentName);
  return !areFileNamesEqual(nameInRegistry, sanitizedName);
};

async function renameFile(
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  newName: string
) {
  const oldPath = serializeId(id)!;
  const newPath = posixPath.join(
    posixPath.dirname(oldPath),
    appendFileExtension(newName)
  );

  await moveFileByPath(newPath, oldPath, folder, workspace);
  upsertDeserializedId(id, getContentRoot(workspace, folder), newPath);
}

/**
 * Writes files to FS. Supports new file creation, file rename and existing file update.
 * @param id deserialized id
 * @param workspace
 * @param folder workspace folder
 * @param content any javascript object
 * @param fileName base name (without directories)
 * @param targetPath file path in folder (eg. /someDeeper/Dir)
 */
export const saveFile = async (
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  content: unknown,
  fileName = "",
  targetPath = ""
): Promise<void> => {
  const registeredName = serializeId(id);

  if (!registeredName) {
    return await createFile(
      id,
      workspace,
      folder,
      posixPath.join(targetPath, appendFileExtension(fileName)),
      content
    );
  }

  await writeFile(registeredName, workspace, folder, content);

  const hasNameUpdated =
    fileName && !areFileNamesEqual(registeredName, fileName);

  if (hasNameUpdated) {
    await renameFile(id, workspace, folder, fileName);
  }
};

export const moveFile = async (
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  targetPath: string
): Promise<void> => {
  const oldPath = serializeId(id);
  if (!oldPath) throw new Error("File name not found inside registry");

  const isFileMoved = posixPath.dirname(oldPath) !== targetPath;
  if (!isFileMoved) return;

  const newPath = posixPath.join(targetPath, posixPath.basename(oldPath));
  await moveFileByPath(newPath, oldPath, folder, workspace);
  upsertDeserializedId(id, getContentRoot(workspace, folder), newPath);
};

export const writeFolder = async (
  workspace: Workspace,
  folder: WorkspaceFolder,
  pathToFolder: string
): Promise<void> => {
  const absPath = posixPath.join(getDirPath(workspace, folder), pathToFolder);
  await mkdir(absPath);
};

export const renameFolder = async (
  currentFolderName: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  folderId: string,
  newName: string
): Promise<{ oldPath: string; newPath: string }> =>
  await moveFolder(
    currentFolderName,
    workspace,
    folder,
    folderId,
    posixPath.join(posixPath.dirname(currentFolderName), newName)
  );

export const moveFolder = async (
  currentFolderName: string,
  workspace: Workspace,
  folder: WorkspaceFolder,
  folderId: string,
  newPath: string
): Promise<{ oldPath: string; newPath: string }> => {
  const currentFolderPath = posixPath.join(
    getDirPath(workspace, folder),
    currentFolderName
  );

  const newFolderPath = posixPath.join(getDirPath(workspace, folder), newPath);

  if (currentFolderPath === newFolderPath) {
    throw new Error("New folder has the same path as the old one");
  }

  const fs = getWorkspaceFS();
  // @ts-expect-error: rename doesn't currently exist on "isomorphic-git" type defs even tho it exists in peer dep "isomorphic-fs"
  await fs.promises.rename(currentFolderPath, newFolderPath);

  const oldPathWithoutEntityBase = posixPath.join(
    getFolderPath(folder),
    currentFolderName
  );
  const newPathWithoutEntityBase = posixPath.join(
    getFolderPath(folder),
    newPath
  );

  upsertDeserializedFolderId(
    folderId,
    getContentRoot(workspace, folder),
    newPath
  );

  return {
    oldPath: oldPathWithoutEntityBase,
    newPath: newPathWithoutEntityBase,
  };
};

export async function readFileByPath(filePath: string): Promise<string> {
  const fs = getWorkspaceFS();
  return await fs.promises.readFile(filePath, { encoding: "utf8" });
}

function readFileByName(
  workspace: Workspace,
  folder: WorkspaceFolder,
  fileName: string
) {
  const dir = getDirPath(workspace, folder);
  const absPath = posixPath.join(dir, fileName);
  return readYaml(absPath);
}

export const readFile = async (
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder
): Promise<unknown> => {
  const fileName = serializeId(id);
  if (!fileName) throw new Error(`File with the id "${id}" not found`);

  return readFileByName(workspace, folder, fileName);
};

export const getFileDisplayName = (
  id: string,
  workspace: Workspace,
  folder: WorkspaceFolder
): string => {
  const fileName = findNode(id, workspace[folder])?.displayName;
  if (!fileName) throw new Error(`File with the id "${id}" not found`);
  return fileName;
};

/**
 * Reads Yaml, converts to JavaScript object
 * @param filePath fs path to a file
 * @returns JavaScript object of parsed YAML file
 */
export const readYaml = async (filePath: string): Promise<unknown> => {
  const fs = getWorkspaceFS();
  const yaml = await fs.promises.readFile(filePath, {
    encoding: "utf8",
  });

  return YAML.parse(yaml);
};

export type YAMLStringifyOptions = Exclude<
  // eslint-disable-next-line no-magic-numbers
  Parameters<typeof YAML.stringify>[2],
  string | number
>;

const YAML_PREVENT_MALFORMED_CODE_STRING: YAMLStringifyOptions = {
  doubleQuotedAsJSON: true,
};

/**
 * Writes Yaml, converts JavaScript object to Yaml format
 * @param filePath fs path to a file
 * @param content any Javascript object
 * @param options YAML options
 */
export const writeYaml = async (
  filePath: string,
  content: unknown,
  options?: YAMLStringifyOptions
): Promise<void> => {
  const yaml = YAML.stringify(content, {
    ...YAML_PREVENT_MALFORMED_CODE_STRING,
    ...options,
  });
  await writeFileDeep(filePath, yaml);
};

export const writeFileDeep = async (
  location: string,
  content: Uint8Array | string
): Promise<void> => {
  const fs = getWorkspaceFS();
  await mkdir(posixPath.dirname(location));
  await fs.promises.writeFile(location, content);
};

export interface ReaddirDeepOpts {
  omitDirsFromResult?: boolean;
  filter?: (name: string) => boolean;
}

export const readdirDeep = async (
  directory: string,
  { omitDirsFromResult, filter }: ReaddirDeepOpts = {}
): Promise<string[]> => {
  const fs = getWorkspaceFS();

  const allDirFiles: string[] = [];

  if (!(await exists(directory))) {
    return [];
  }

  const read = async (dir: string) => {
    const localFiles = await fs.promises.readdir(dir);

    for (const localFile of localFiles) {
      const file = posixPath.join(dir, localFile);

      if (await isDir(file)) {
        await read(file);
        if (omitDirsFromResult) continue;
      }

      allDirFiles.push(file);
    }
  };

  await read(directory);

  return allDirFiles.filter(filter ?? (() => true));
};

export interface RmdirDeepOpts {
  skipLocationRoot?: boolean;
}

export const rmdirDeep = async (
  directory: string,
  { skipLocationRoot }: RmdirDeepOpts = {}
): Promise<void> => {
  const fs = getWorkspaceFS();

  const rmdir = async (dir: string) => {
    const localFiles = await fs.promises.readdir(dir);

    for (const localFile of localFiles) {
      const file = posixPath.join(dir, localFile);

      if (await isDir(file)) {
        await rmdir(file);
        await fs.promises.rmdir(file);
        continue;
      }

      await fs.promises.unlink(file);
    }
  };

  await rmdir(directory);

  if (!skipLocationRoot) {
    await fs.promises.rmdir(directory);
  }
};

export function isSubdir(parent: string, dir: string): boolean {
  const relativePath = posixPath.relative(parent, dir);
  return relativePath !== "" && !relativePath.startsWith("..");
}

/***
 * Deletes the invald dirs and files from the root level
 */

export const cleanDirectory = async (
  location: string,
  allowedPaths: string[]
): Promise<void> => {
  const fs = getWorkspaceFS();

  const files: string[] = await fs.promises.readdir(location);

  for (const file of files) {
    if (!allowedPaths.includes(file)) {
      const filePath = posixPath.join(location, file);

      if (await isDir(filePath)) {
        await rmdirDeep(filePath);
        console.warn(`Invalid folder: ${file} deleted`);
      } else {
        await fs.promises.unlink(filePath);
        console.warn(`Invalid file: ${file} deleted`);
      }
    }
  }
};

export const asYamlExt = (path: string): string =>
  path.endsWith(".yaml") ? path : `${path}.yaml`;
export const omitYamlExt = (path: string): string =>
  path.endsWith(".yaml") ? path.slice(0, -".yaml".length) : path;
