import {
  ActionCreatorWithPayload,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";

import { Pages } from "../../../model";
import { ObjEntries } from "../../../utils/ObjectUtils";
import { remove as removeApi } from "../../apis/reducers";
import { setUnsavedEntity } from "../../designer/caseReducers/utils/setUnsavedEntity";
import { remove as removeHandlers } from "../../error-handlers/reducers";
import { RootState } from "../../index";
import { remove as removeSchedule } from "../../schedules/reducers";
import { remove as removeSchemas } from "../../schemas/reducers";
import { remove as removeVariables } from "../../variables/reducers";
import { remove as removeVirtualSteps } from "../../virtual-steps/reducers";

const removeActionMap: Record<
  //   flow has a separate handling
  Exclude<keyof Pages, "flow">,
  ActionCreatorWithPayload<string, string>
> = {
  handlers: removeHandlers,
  schemas: removeSchemas,
  variables: removeVariables,
  virtualSteps: removeVirtualSteps,
  api: removeApi,
  scheduler: removeSchedule,
};

export const addUnsavedEntityCaseReducers = (
  builder: ActionReducerMapBuilder<RootState>
): void => {
  ObjEntries(removeActionMap).forEach(([page, action]) => {
    builder.addCase(action, (state, _action) => {
      setUnsavedEntity(state.designer, page, null);
    });
  });
};
