import {
  UserDesignerSettings,
  UserSettings,
} from "@sapiens-digital/ace-designer-common";
import { DesignerConfig } from "@sapiens-digital/ace-designer-common/lib/model/designerSettings";
import { DEFAULT_GIT_AUTHOR } from "constant/uiConstants";

import { RUNTIME_SERVER_DEFAULT_PORT } from "../constant";

import { getEnvironmentVariables } from "./settings";
import { Environment, getEnvironment } from "./workspace";

export interface GitAuthor {
  name: string;
  email: string;
}

export class SettingsManager {
  private static userDesignerSettings: UserDesignerSettings;
  private static designerConfig: DesignerConfig;
  private static gitAuthor: GitAuthor | null;
  private static gitAuthorKeyCloak: GitAuthor | null;

  public static setDesignerSettings(initSettings: UserSettings): void {
    if (initSettings.fullName && initSettings.email) {
      this.setGitAuthor({
        name: initSettings.fullName,
        email: initSettings.email,
      });
    } else this.resetGitAuthor();

    // Update only once per session. Any changes done during application lifecycle will be loaded on next load.
    if (!this.userDesignerSettings) {
      this.userDesignerSettings = initSettings;
    }
  }

  public static async init(): Promise<void> {
    this.designerConfig = await getEnvironmentVariables();
  }

  public static getSwaggerServerUrl(): string | undefined {
    const isElectron = getEnvironment() === Environment.Electron;

    if (isElectron) {
      const port =
        this.userDesignerSettings.runtimeServerPort ||
        RUNTIME_SERVER_DEFAULT_PORT;
      return `http://localhost:${port}`;
    }

    return `/dev`;
  }

  public static constructApiDefinitionUrl(apiName: string): string | undefined {
    if (this.designerConfig.publicApiBaseUrl) {
      return `#/api-definition?api=${apiName}`;
    }
  }

  public static getWorkspacesLocation(): string {
    return this.userDesignerSettings.workspacesLocation;
  }

  public static getIsDeprecatedStepsEnabled(): boolean {
    return this.designerConfig.enableDeprecatedSteps;
  }
  public static setGitAuthor(author: GitAuthor): void {
    this.gitAuthor = author;
  }

  public static setGitKeycloakAuthor(author: GitAuthor): void {
    this.gitAuthorKeyCloak = author;
  }

  public static resetGitAuthor(): void {
    this.gitAuthor = null;
  }

  public static resetGitKeycloakAuthor(): void {
    this.gitAuthorKeyCloak = null;
  }

  public static getGitAuthor(): GitAuthor {
    return this.gitAuthorKeyCloak || this.gitAuthor || DEFAULT_GIT_AUTHOR;
  }

  public static getDesignerConfigs(): DesignerConfig {
    return this.designerConfig;
  }
}
