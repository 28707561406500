import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { Folder } from "../../model/file";
import { Workspace, WorkspaceFolder } from "../../model/workspace";
import {
  findNode,
  getAllChildNodes,
  getFolderFileNodes,
} from "../../services/nodes";
import { ALL_NODES_ID } from "../designer/constants";
import { makeSelectOpenTabbableIds } from "../utils/selectors.tabbable";
import { RootState } from "..";

const BRANCH_PLACEHOLDER_NAME = "<unknown>";

export const selectSelectedWorkspaceId = (
  state: RootState
): string | undefined => state.settings.selectedWorkspaceId;

export const selectSelectedWorkspace = createSelector(
  (state: RootState) => state,
  selectSelectedWorkspaceId,
  (state: RootState, wsId?: string) =>
    wsId ? workspaceSelectors.selectById(state, wsId) : undefined
);

export const selectSelectedFlowId = createSelector(
  selectSelectedWorkspace,
  (ws) => ws?.selectedFlowId
);

export const workspacesAdapter = createEntityAdapter<Workspace>({
  selectId: (workspace) => workspace.id,
});

export const workspaceSelectors = workspacesAdapter.getSelectors<RootState>(
  (state) => state.workspaces
);

export const selectDefaultBranchId = (state: RootState): string | undefined =>
  workspaceSelectors
    .selectAll(state)
    .find((item) => item.name === state.settings.repositoryDefaultBranch)?.id;

export const selectDefaultBranchName = createSelector(
  (state: RootState) => state,
  selectDefaultBranchId,
  (state: RootState, defaultBranchId?: string): string | undefined =>
    workspaceSelectors.selectById(
      state,
      state.settings.selectedWorkspaceId ??
        defaultBranchId ??
        BRANCH_PLACEHOLDER_NAME
    )?.name
);

export const selectWorkspaceIdByName = (
  name: string,
  state: RootState
): Workspace | undefined =>
  workspaceSelectors.selectAll(state).find((item) => item.name === name);

export const selectFolder = createSelector(
  selectSelectedWorkspace,
  (_state: RootState, workspaceFolder: WorkspaceFolder) => workspaceFolder,
  (selectedWorkspace, workspaceFolder) =>
    selectedWorkspace?.[workspaceFolder] || []
);

export const selectFlowFolder = (state: RootState): Folder =>
  selectFolder(state, "flows");

export const selectNodes = createSelector(
  (state: RootState, folder: WorkspaceFolder) => selectFolder(state, folder),
  (_state: RootState, _folder: WorkspaceFolder, selectedFolderId?: string) =>
    selectedFolderId,
  (selectedFolder, selectedFolderId) => {
    if (selectedFolderId === undefined || selectedFolderId === ALL_NODES_ID) {
      return getFolderFileNodes(selectedFolder);
    }

    return getAllChildNodes(findNode(selectedFolderId, selectedFolder), true);
  }
);

export const selectOpenFlowIds = makeSelectOpenTabbableIds("flow");
