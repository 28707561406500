export const HEADER_AUTH_STATUS_REJECTED = "rejected";
export const HEADER_ACCESS_CONTROL_EXPOSE_HEADERS =
  "Access-Control-Expose-Headers";
export const HEADER_DEVELOPMENT = "ace-development";
export const HEADER_AUTH_STATUS = "ace-auth-status";
export interface DevelopmentRequestMetadata {
  repository: string;
  token: string;
  branch: string;
  authorization: string;
  commit?: string;
  username?: string;
  repositoryWorkspacePath: string;
}
