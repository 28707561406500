import { asBearer } from "@sapiens-digital/ace-designer-common";
import { IdTokenContent } from "@sapiens-digital/ace-designer-common/lib/helpers/token";

const KEY_ACCESS_TOKEN = "Authorization";
const KEY_USER_INFO = "User";

export function getAuthorizationHeader(): string {
  return asBearer(localStorage.getItem(KEY_ACCESS_TOKEN) || "");
}

export function getAuthorizationUser(): IdTokenContent {
  const preset: IdTokenContent = {
    username: undefined,
    name: undefined,
    email: undefined,
  };

  try {
    const user = localStorage.getItem(KEY_USER_INFO);

    if (!user) {
      return preset;
    }

    return JSON.parse(user);
  } catch (e) {
    console.warn(`Could not parse user details from local storage`, e);
    return preset;
  }
}
