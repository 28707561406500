import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const jwtStepSchema: StepSchema = {
  stepType: Steps.JWT,
  color: "rgb(204,229,255)",
  jsonSchema: {
    title: "JWT",
    description: "Allows to decode, verify and generate JWT",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["mode"],
        properties: {
          mode: {
            title: "Mode",
            type: "string",
            enum: ["sign", "verify", "decode"],
            default: "sign",
          },
          targetPath: {
            type: "string",
            title: "Target path - define the path in which to store the result",
          },
        },
        dependencies: {
          mode: {
            oneOf: [
              {
                properties: {
                  mode: {
                    enum: ["sign"],
                  },
                  algorithm: {
                    title: "Algorithm",
                    type: "string",
                    enum: ["HS256", "RS256"],
                    default: "HS256",
                  },
                },
                dependencies: {
                  algorithm: {
                    oneOf: [
                      {
                        properties: {
                          algorithm: {
                            enum: ["HS256"],
                          },
                          secret: {
                            type: "string",
                            title: "SALT",
                          },
                          json: {
                            type: ["object", "null", "string"],
                            title: "data",
                          },
                          storeIn: {
                            type: "string",
                            title:
                              "Where to store encoded data in token, . if in root",
                            default: ".",
                          },
                          expiresIn: {
                            type: "string",
                            title:
                              "expires in (example: 1s, 1m, 1h, 1d, 1m, 1y)",
                          },
                        },
                      },
                      {
                        properties: {
                          algorithm: {
                            enum: ["RS256"],
                          },
                          secret: {
                            type: "string",
                            title: "Private key",
                          },
                          json: {
                            type: ["object", "null", "string"],
                            title: "data",
                          },
                          storeIn: {
                            type: "string",
                            title:
                              "Where to store encoded data in token, . if in root",
                            default: ".",
                          },
                          expiresIn: {
                            type: "string",
                            title:
                              "expires in (example: 1s, 1m, 1h, 1d, 1m, 1y)",
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                properties: {
                  mode: {
                    enum: ["verify"],
                  },
                  algorithm: {
                    title: "Algorithm",
                    type: "string",
                    enum: ["HS256", "RS256"],
                    default: "HS256",
                  },
                },
                dependencies: {
                  algorithm: {
                    oneOf: [
                      {
                        properties: {
                          algorithm: {
                            enum: ["HS256"],
                          },
                          secret: {
                            type: "string",
                            title: "SALT",
                          },
                          token: {
                            type: "string",
                            title: "Token",
                          },
                        },
                      },
                      {
                        properties: {
                          algorithm: {
                            enum: ["RS256"],
                          },
                          secret: {
                            type: "string",
                            title: "Public key",
                          },
                          token: {
                            type: "string",
                            title: "Token",
                          },
                        },
                      },
                    ],
                  },
                },
              },
              {
                properties: {
                  mode: {
                    enum: ["decode"],
                  },
                  token: {
                    type: "string",
                    title: "Token",
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      json: {
        "ui:field": "json",
      },
      secret: {
        "ui:widget": "textarea",
      },
    },
  },
};
