export const DEFAULT_VARIABLES_FILENAME = "env";

export type SerializedEnvVariable = {
  name: string;
  value: string;
};

export type SerializedEnvVariables = {
  variables: SerializedEnvVariable[];
};
