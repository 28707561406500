import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isRejected } from "@reduxjs/toolkit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Avatar,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { UserSettings } from "@sapiens-digital/ace-designer-common";

import { Remote } from "../../model/workspace";
import { GIT_ERROR_BRANCH_HAS_DIVERGED } from "../../services/git-utils";
import { RootState, useAppDispatch } from "../../store";
import {
  closeWorkspaceManager,
  setDesignerLoading,
} from "../../store/designer/reducers";
import {
  deleteRemoteAction,
  loadRemotesAction,
  selectRemoteAction,
} from "../../store/remotes/actions";
import { remoteSelectors } from "../../store/remotes/reducers";
import WorkspaceIcon from "../icons/Workspace";
import { notify } from "../utils/notify";

import { DeleteWorkspaceDialog } from "./DeleteWorkspaceDialog";

const WorkspaceListItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
  },
}));

enum LoadStatus {
  init,
  empty,
  loaded,
  loadError,
}

const WorkspaceManagerRemote: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [workspaceToDelete, setWorkspaceToDelete] = useState(
    undefined as Remote | undefined
  );
  const [deleteInProgress, setDeleteInProgress] = useState<
    Record<string, boolean>
  >({});
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(LoadStatus.init);
  const [error, setError] = useState("");

  const settings = useSelector<RootState, UserSettings>(
    (state) => state.settings
  );

  useEffect(() => {
    const loadRemotes = async () => {
      if (
        settings.repositoryUrl === undefined ||
        settings.repositoryToken === undefined
      ) {
        setStatus(LoadStatus.loadError);
        setError(`Please setup repository URL and token to continue.`);
        return;
      }

      const load = await dispatch(loadRemotesAction());

      if (isRejected(load)) {
        setStatus(LoadStatus.loadError);
        setError(
          `Unexpected error loading remote branch, code=${load.error.code} message=${load.error.message}`
        );
        return;
      }

      if (load.payload.length === 0) {
        setStatus(LoadStatus.empty);
        return;
      }

      setStatus(LoadStatus.loaded);
    };

    loadRemotes();
  }, [
    dispatch,
    settings.repositoryToken,
    settings.repositoryUrl,
    settings.repositoryUsername,
  ]);

  const remotes = useSelector<RootState, Array<Remote>>((state) =>
    remoteSelectors.selectAll(state)
  );

  remotes.sort();

  const [items, setItems] = useState(remotes);

  useEffect(() => {
    setItems(remotes);
  }, [remotes]);

  const filterItems = (value: string) => {
    setSearch(value);
    setItems(
      remotes.filter((item) =>
        item.name.toLowerCase().includes(value.trim().toLowerCase())
      )
    );
  };

  const handleDelete = async (remote: Remote) => {
    deleteInProgress[remote.id] = true;
    setDeleteInProgress({ ...deleteInProgress });
    const deleteRemoteResult = await dispatch(
      deleteRemoteAction({ ...remote })
    );
    notify(deleteRemoteResult, dispatch, `Workspace ${remote.name} is deleted`);
    deleteInProgress[remote.id] = false;
    setDeleteInProgress({ ...deleteInProgress });
  };

  const handleOpen = (workspace: Remote) => {
    setOpen(true);
    setWorkspaceToDelete(workspace);
  };

  const handleClose = () => {
    setOpen(false);
    setWorkspaceToDelete(undefined);
  };

  let content;

  const renderDeleteButton = (item: Remote) => {
    if (item.name === settings.repositoryDefaultBranch) {
      return undefined;
    }

    return (
      <IconButton
        disabled={deleteInProgress[item.id]}
        onClick={() => {
          handleOpen(item);
        }}
        sx={{
          color: "grey.700",
          "&:hover": {
            backgroundColor: "transparent",
            color: "danger",
          },
        }}
        size="large"
      >
        <Avatar sx={{ color: "inherit", backgroundColor: "grey.400" }}>
          <DeleteOutlineIcon />
        </Avatar>
      </IconButton>
    );
  };

  if (status === LoadStatus.init) {
    content = <LinearProgress />;
  } else if (status === LoadStatus.loaded) {
    content = (
      <>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          label="Search"
          value={search}
          onChange={(event) => filterItems(event.target.value)}
        />
        <List sx={{ overflowY: "auto", marginTop: "20px", height: "40vh" }}>
          {items.map((item) => (
            <WorkspaceListItemContainer key={`workspace-${item.id}`}>
              <ListItemButton
                key={item.id}
                disabled={deleteInProgress[item.id]}
                onClick={async () => {
                  setStatus(LoadStatus.init);
                  dispatch(setDesignerLoading(true));
                  const select = await dispatch(selectRemoteAction(item.name));

                  if (
                    isRejected(select) &&
                    select.error.message !== GIT_ERROR_BRANCH_HAS_DIVERGED
                  ) {
                    setStatus(LoadStatus.loadError);
                    setError(
                      `code=${select.error.code} message=${
                        select.error.message
                      } value=${select.payload as string}`
                    );
                    dispatch(setDesignerLoading(false));
                    return;
                  }

                  dispatch(closeWorkspaceManager());
                  dispatch(setDesignerLoading(false));
                }}
              >
                <ListItemIcon>
                  <WorkspaceIcon />
                </ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </ListItemButton>
              {renderDeleteButton(item)}
            </WorkspaceListItemContainer>
          ))}
        </List>
        {workspaceToDelete && (
          <DeleteWorkspaceDialog
            open={open}
            workspace={workspaceToDelete}
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
        )}
      </>
    );
  } else if (status === LoadStatus.empty) {
    content = <Typography>There are no remote branches.</Typography>;
  } else if (status === LoadStatus.loadError) {
    content = (
      <Typography>
        Failed to load remote branches. Error message: "{error}"{" "}
      </Typography>
    );
  }

  return (
    <>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeWorkspaceManager());
          }}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkspaceManagerRemote;
