import { WorkspaceDetails } from "../model/git";
import { getBase64EncodedHeaders } from "./getbase64EncodedOrDecodedHeaders";

export const createAceHeaders = (
  deploymentDetails: WorkspaceDetails,
  featurePersistentSettings = false
): string => {
  return getBase64EncodedHeaders(
    featurePersistentSettings
      ? {
          branch: deploymentDetails.branch,
          commit: deploymentDetails.commit,
        }
      : {
          repository: deploymentDetails.repository,
          token: deploymentDetails.token,
          branch: deploymentDetails.branch,
          commit: deploymentDetails.commit,
          username: deploymentDetails.username,
          repositoryWorkspacePath: deploymentDetails.repositoryWorkspacePath,
        }
  );
};
