import { stepNameColorMap } from "@sapiens-digital/ace-designer-common/lib/steps/stepRegistry";
import { StepListItem } from "components/flow/step-list/StepList";
import { Step } from "model";

export function getFlowStepListItem(
  step: Step,
  selectedStepId: string | undefined
): StepListItem {
  return {
    id: step.id,
    isSelected: step.id === selectedStepId,
    subtitle: step.description || "",
    title: step.name || step.stepType,
    step: step,
    tooltip: step.stepType,
    iconColor: stepNameColorMap[step.stepType],
  };
}
