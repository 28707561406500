import { PayloadAction } from "@reduxjs/toolkit";

import {
  CommonPageData,
  Designer,
  ExtractDesignerPageEntity,
  Page,
} from "../../../model";
import { toggle } from "../../utils/toggle";
import { initialPageStateData } from "../initialState";

import { pageHasFileTree } from "./utils/pageHasFileTree";
import { setPageProperty } from "./utils/setPageProperty";
import { setUnsavedEntity } from "./utils/setUnsavedEntity";
import { TransformToReducers } from "./utils/types/CommonPageDataCaseReducersExplicitType";
import { keyVal, toSnakeCase } from "./utils";

export type PageData<TPage extends Page> = CommonPageData<
  ExtractDesignerPageEntity<TPage>
>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCaseReducerNameMapping = <TPage extends Page>(page: TPage) => ({
  select: toSnakeCase("select", page),
  deselect: toSnakeCase("deselect", page),
  setPage: toSnakeCase("set", page, "page"),
  setSearch: toSnakeCase("set", page, "search"),
  setSort: toSnakeCase("set", page, "sort"),
  setActiveToggleFilters: toSnakeCase("set", page, "activeToggleFilters"),
  toggleActiveFilters: toSnakeCase("toggle", page, "activeFilters"),
  setUnsavedEntity: toSnakeCase("set", page, "unsavedEntity"),
  resetFilters: toSnakeCase("reset", page, "filters"),
});

const getCommonPageCaseReducers = <TPage extends Page>(
  page: TPage
): TransformToReducers<TPage> => {
  const names = getCaseReducerNameMapping(page);
  return Object.assign(
    {},
    keyVal(
      names.select,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["selectedItemId"]>
      ) => {
        const currentSelectedItemId = state.pages[page].selectedItemId;

        if (currentSelectedItemId !== action.payload) {
          setUnsavedEntity(state, page, null);
        }

        setPageProperty(state, page, "selectedItemId", action.payload);
      }
    ),
    keyVal(names.deselect, (state: Designer) => {
      setPageProperty(state, page, "selectedItemId", "");
    }),
    keyVal(
      names.setPage,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["selectedPage"]>
      ) => {
        setPageProperty(state, page, "selectedPage", action.payload);
      }
    ),
    keyVal(
      names.setSearch,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["searchTerm"]>
      ) => {
        setPageProperty(state, page, "searchTerm", action.payload);
      }
    ),
    keyVal(
      names.setSort,
      (state: Designer, action: PayloadAction<PageData<TPage>["sort"]>) => {
        setPageProperty(state, page, "sort", action.payload);
      }
    ),
    keyVal(
      names.setActiveToggleFilters,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["activeToggleFilters"]>
      ) => {
        setPageProperty(state, page, "activeToggleFilters", action.payload);
      }
    ),
    keyVal(
      names.toggleActiveFilters,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["activeToggleFilters"][number]>
      ) => {
        setPageProperty(
          state,
          page,
          "activeToggleFilters",
          toggle(state.pages[page].activeToggleFilters, action.payload)
        );
      }
    ),
    keyVal(
      names.setUnsavedEntity,
      (
        state: Designer,
        action: PayloadAction<PageData<TPage>["unsavedEntity"]>
      ) => {
        setUnsavedEntity(state, page, action.payload);
      }
    ),
    keyVal(
      names.resetFilters,
      (
        state: Designer,
        action: PayloadAction<undefined | Partial<PageData<TPage>>>
      ) => {
        const {
          activeToggleFilters,
          searchTerm,
          sort,
          selectedPage,
          selectedItemId,
        } = action.payload || {};
        setPageProperty(
          state,
          page,
          "activeToggleFilters",
          activeToggleFilters ?? []
        );
        setPageProperty(state, page, "searchTerm", searchTerm ?? "");
        setPageProperty(state, page, "sort", sort ?? "");
        setPageProperty(state, page, "selectedPage", selectedPage ?? 1);
        setPageProperty(state, page, "selectedItemId", selectedItemId ?? "");

        if (pageHasFileTree(page)) {
          const initialSelectedFolderId =
            initialPageStateData[page]["selectedFolderId"];
          setPageProperty(
            state,
            page,
            "selectedFolderId",
            initialSelectedFolderId
          );

          const initialSelectedTreeItemId =
            initialPageStateData[page]["selectedTreeItemId"];
          setPageProperty(
            state,
            page,
            "selectedTreeItemId",
            initialSelectedTreeItemId
          );
        }
      }
    )
  );
};

export const getCommonPageDataCaseReducers = <TPage extends Page>(
  page: TPage
): TransformToReducers<TPage> => getCommonPageCaseReducers<TPage>(page);
