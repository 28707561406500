import _ from "lodash";
import { LogFn, MergeRule } from "./merge";
import { isPlainObject, safeFromYaml, safeToYaml } from "../parsing";
import { WORKSPACE_PATHS } from "../../model";

export const mergeApis: MergeRule = {
  fileApplies: isApiPathParts,
  modify: mergeApiFiles,
};

export function isApiPathParts(relativePathParts: string[]): boolean {
  const pathsDepth = 2;
  return (
    relativePathParts.length === pathsDepth &&
    relativePathParts[0] == WORKSPACE_PATHS.APIS &&
    relativePathParts[1].endsWith(".yaml")
  );
}

export function mergeApiFiles(
  newContent: string,
  existingContent: string,
  log: LogFn
): string {
  const exsJson = safeFromYaml(existingContent);
  const newJson = safeFromYaml(newContent);

  const isExsJsonObj = isPlainObject(exsJson);
  const isNewJsonObj = isPlainObject(newJson);

  if (isExsJsonObj && isNewJsonObj) {
    mergeApisContentInPlace(newJson, exsJson, log);
    return safeToYaml(exsJson);
  }

  if (!isExsJsonObj && isNewJsonObj) {
    log("can't merge invalid target document, overwriting");
    return newContent;
  }

  log("can't merge invalid source document, skipping");
  return existingContent;
}

export function mergeApisContentInPlace(
  source: Record<string, unknown>,
  target: Record<string, unknown>,
  log: LogFn
): void {
  Object.entries(source).forEach(([key, value]) => {
    if (key === "paths") {
      return;
    }
    _.set(target, key, value);
  });

  const paths = source.paths;
  isPlainObject(paths) &&
    Object.entries(paths).forEach(([path, value]) => {
      const methods = isPlainObject(value) && Object.entries(value);
      methods &&
        methods.forEach(([method, value]) => {
          log(`copying endpoint '${path}/${method}'`);
          _.set(target, ["paths", path, method], value);
        });
    });
}
