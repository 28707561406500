import { Designer, Pages } from "../../../../model";

export function setPageProperty<
  TPage extends keyof Pages,
  TProp extends keyof Pages[TPage]
>(
  state: Designer,
  page: TPage,
  property: TProp,
  value: Pages[TPage][TProp]
): void {
  state.pages[page][property] = value;
}
