import { posixPath } from "./posixPath";

export const REGEX_SEPARATORS = /[\\/]/g;

export const split = (location: string): Array<string> => {
  const parts = location.split(REGEX_SEPARATORS);

  if (location.trim() === posixPath.sep) {
    return [posixPath.sep];
  }

  return parts.map((part, index) => {
    if (index === 0 && part === "") {
      return posixPath.sep;
    }

    return `${part}`;
  });
};
