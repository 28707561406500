import path from "path";
import { ALL_WORKSPACE_PATHS, WORKSPACE_PATHS as PATHS } from "../../model";
import {
  Fs,
  copyFilesDeep,
  exists,
  isDir,
  mkdir,
  safeRead,
  safeWrite,
} from "../fsUtils";
import { mergeApis } from "./mergeApis";
import { mergeEnvs } from "./mergeEnvs";
import { split } from "../paths";

export type LogFn = (msg: string) => void;
export type MergeRule = {
  fileApplies: (relativePathParts: string[]) => boolean;
  modify: (newContent: string, existingContent: string, log: LogFn) => string;
};

export const mergeWorkspaceV2 = async (
  fs: Fs,
  sourceWorkspacePath: string,
  targetWorkspacePath: string,
  log: LogFn
): Promise<void> => {
  const ignoreList = [String(PATHS.APIS), String(PATHS.VARIABLES)];

  const copyFolders = ALL_WORKSPACE_PATHS.filter(
    (f) => !ignoreList.includes(f)
  ).map(async (folder) => {
    const sourceFolder = path.join(sourceWorkspacePath, folder);
    const targetFolder = path.join(targetWorkspacePath, folder);
    const result = await copyFilesDeep(fs, sourceFolder, targetFolder, ".yaml");
    result.forEach((p) => log(`copying '${path.join(folder, p)}'`));
  });

  const paths: Omit<MergeMode, "rule" | "folder"> = {
    sourceWsPath: sourceWorkspacePath,
    targetWsPath: targetWorkspacePath,
  };

  const apisMode = { rule: mergeApis, folder: String(PATHS.APIS) };
  const envsMode = { rule: mergeEnvs, folder: String(PATHS.VARIABLES) };

  await Promise.all(copyFolders);
  await Promise.all([
    mergeShallowFolder(fs, { ...paths, ...apisMode }, log),
    mergeShallowFolder(fs, { ...paths, ...envsMode }, log),
  ]);
};

type MergeMode = {
  sourceWsPath: string;
  targetWsPath: string;
  folder: string;
  rule: MergeRule;
};
const mergeShallowFolder = async (
  fs: Fs,
  mode: MergeMode,
  log: LogFn
): Promise<void> => {
  const sourceFolder = path.resolve(path.join(mode.sourceWsPath, mode.folder));
  const targetFolder = path.resolve(path.join(mode.targetWsPath, mode.folder));

  if (!(await isDir(fs, sourceFolder))) {
    return;
  }

  if (!(await exists(fs, targetFolder))) {
    await mkdir(fs, targetFolder);
  }

  const files = await fs.promises.readdir(sourceFolder);
  await Promise.all(
    files.map(async (relativePath) => {
      const workspaceRelativePath = path.join(mode.folder, relativePath);

      const sourcePath = path.join(sourceFolder, relativePath);
      const targetPath = path.join(targetFolder, relativePath);

      if (!mode.rule.fileApplies(split(workspaceRelativePath))) {
        return;
      }

      const sourceContent = await safeRead(fs, sourcePath, log);
      const targetContent = await safeRead(fs, targetPath, log);

      if (sourceContent === undefined) {
        log(`failed to read, skipping '${sourceContent}'`);
        return;
      }
      if (targetContent === undefined) {
        log(`copying '${workspaceRelativePath}'`);
        await safeWrite(fs, targetPath, sourceContent, log);
        return;
      }

      log(`merging '${workspaceRelativePath}'`);
      const merged = mode.rule.modify(sourceContent, targetContent, log);
      await safeWrite(fs, targetPath, merged, log);
    })
  );
};
