import { REF_PREFIX } from "../references/ace4";
import { mapObject } from "./mapObject";

export function mapLocalRefsToOpenApiFormat<TSchema>(schema: TSchema): TSchema {
  const result = mapObject<TSchema>(schema, (key, val) => {
    if (
      key === "$ref" &&
      typeof val === "string" &&
      val.toLowerCase().endsWith(".yaml")
    ) {
      const nameParts = val.split("/");
      if (nameParts.length === 0) {
        return val;
      }

      const schemaName = nameParts[nameParts.length - 1].slice(
        0,
        -".yaml".length
      );
      return `${REF_PREFIX}${schemaName}`;
    }

    return val;
  });

  return result;
}
