import React, { useEffect } from "react";

import { closeWindowAfterRemovingAuthDetails, isLogoutAction } from "./utils";

const LoginTracker: React.FC = () => {
  useEffect(() => {
    if (isLogoutAction()) closeWindowAfterRemovingAuthDetails();
  }, []);

  return null;
};

export default LoginTracker;
