import React, { useCallback } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, IconButton, Tooltip } from "@mui/material";
import { SettingsManager } from "@sapiens-digital/ace-designer-app/app/services/settingsManager";
import { useAppSelector } from "@sapiens-digital/ace-designer-app/app/store";
import { selectUsername } from "@sapiens-digital/ace-designer-app/app/store/designer/selectors";

const UserLogout: React.FC = () => {
  const username = useAppSelector(selectUsername);

  const logout = useCallback(() => {
    const keycloakLogoutPath = `/auth/${
      SettingsManager.getDesignerConfigs().authStrategy
    }/logout`;
    window.open(keycloakLogoutPath);
  }, []);

  return username ? (
    <>
      <Tooltip title={username} placement="top" enterDelay={500}>
        <Box
          sx={{
            color: "white",
            px: 1,
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            textAlign: "center",
          }}
        >
          {username}
        </Box>
      </Tooltip>
      <Tooltip title="Logout" enterDelay={500}>
        <IconButton
          data-testid="logout"
          sx={{ color: "white" }}
          onClick={logout}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </>
  ) : null;
};

export default UserLogout;
