import { generateSchemaFromJson } from "@sapiens-digital/ace-designer-common/lib/helpers/generateSchemaFromJson";
import { SerializedApi } from "@sapiens-digital/ace-designer-common/lib/model/api";
import {
  APIS_FOLDERS,
  WORKSPACE_PATHS,
} from "@sapiens-digital/ace-designer-common/lib/model/workspacePaths";
import _ from "lodash";
import { OpenAPIV3 } from "openapi-types";
import path from "path";
import { createEmptyApi } from "utils/factory";
import { createSortYamlMapEntries } from "utils/sortYaml";
import { v4 as uuidv4 } from "uuid";

import { readFileByPath, readYaml, YAMLStringifyOptions } from "../../fs-utils";
import { updateDeprecatedRefFormatInSchema } from "../schemaReferences";

import { V1EntityMigrator } from "./migrate";
import { saveAsYaml } from "./migrateUtils";

/**
 * ACE 4 file structure
 */
export type DynamicApi = {
  path: string;
  verb: string;
  tag?: string;
  summary?: string;
  responseDescription?: string;
  flow?: string;
  dataModel?: OpenAPIV3.ParameterObject[];
  secured?: boolean;
  responseSchema?: OpenAPIV3.ResponseObject; //TODO: response example
  errorTags?: string[];
  requestBody?: OpenAPIV3.RequestBodyObject;
  requestExample?: OpenAPIV3.ExampleObject; //TODO: request example
  responses?: OpenAPIV3.ResponsesObject;
  operationId?: string;
  version?: number; //ignored
};

const OPTIONS = ["components", "info", "openapi", "paths", ""];
const YAML_OPTIONS: YAMLStringifyOptions = {
  sortMapEntries: createSortYamlMapEntries(OPTIONS),
};

const migrateApi: V1EntityMigrator = async ({
  content,
  fileBasename,
  targetRepositoryLocation,
  repositoryLocation,
  overwriteExisting,
  workspaceEntityPaths,
}) => {
  const dynamicApis = content as DynamicApi[];
  const sourceFile = await readApiFile(repositoryLocation as string);

  if (overwriteExisting) {
    sourceFile.paths = {};
  }

  const flowsNamePathMapping: Record<string, string> = {};
  const flowPaths = workspaceEntityPaths?.flows;
  flowPaths?.forEach((flowPath) => {
    flowsNamePathMapping[path.basename(flowPath, ".json")] = flowPath;
  });

  for (const dynamicApi of dynamicApis) {
    const verb = dynamicApi.verb.toLowerCase() as OpenAPIV3.HttpMethods;
    const operation = sourceFile.paths[dynamicApi.path] || {};
    const requestBody: OpenAPIV3.RequestBodyObject =
      (await checkFlowAttachedForUpload(dynamicApi, flowsNamePathMapping))
        ? {
            description: "",
            content: {
              "multipart/form-data": {
                schema: {
                  type: "object",
                  properties: {
                    files: {
                      type: "string",
                      format: "binary",
                    },
                  },
                },
              },
            },
          }
        : getBodyParam(dynamicApi);

    operation[verb] = {
      "x-ace-flow": dynamicApi.flow + ".yaml",
      "x-ace-error-handlers": dynamicApi.errorTags ? dynamicApi.errorTags : [],
      "x-ace-secured": dynamicApi.secured,
      responses: mapResponses(dynamicApi),
      operationId: dynamicApi.operationId || uuidv4(),
      ...getParams(dynamicApi),
      ...(dynamicApi.summary && { summary: dynamicApi.summary }),
      ...(dynamicApi.tag && { tags: [dynamicApi.tag] }),
      requestBody,
    };
    sourceFile.paths[dynamicApi.path] = updateDeprecatedRefFormatInSchema(
      operation,
      APIS_FOLDERS.SCHEMAS
    ) as typeof operation;
  }

  await saveAsYaml(
    targetRepositoryLocation,
    WORKSPACE_PATHS.APIS,
    "ace",
    sourceFile,
    YAML_OPTIONS
  );

  return [];
};

async function checkFlowAttachedForUpload(
  dynamicApi: DynamicApi,
  flowsNamePathMapping: Record<string, string>
): Promise<boolean> {
  if (dynamicApi.flow) {
    const flowToRead = dynamicApi.flow.split(".json")[0]?.toLowerCase();
    const readFlowJSONString =
      (flowsNamePathMapping[flowToRead] &&
        (await readFileByPath(flowsNamePathMapping[flowToRead]))) ||
      "";
    return readFlowJSONString.search("result.uploadFile") !== -1 ? true : false;
  }

  return false;
}

export function getParams(
  dynamicApi: DynamicApi
):
  | { parameters: (OpenAPIV3.ReferenceObject | OpenAPIV3.ParameterObject)[] }
  | undefined {
  return Array.isArray(dynamicApi.dataModel)
    ? {
        parameters: [
          ...getQueryParameters(dynamicApi.dataModel),
          ...getPathParams(dynamicApi),
          ...getHeaderParams(dynamicApi),
        ],
      }
    : undefined;
}

function mapResponses(dynamicApi: DynamicApi): OpenAPIV3.ResponsesObject {
  if (dynamicApi.responseSchema) {
    return {
      200: {
        description: dynamicApi.responseDescription || "",
        content: {
          "application/json": {
            schema:
              dynamicApi.responseSchema &&
              generateSchemaFromJson(dynamicApi.responseSchema),
          },
        },
      },
    };
  }

  return dynamicApi.responses
    ? dynamicApi.responses
    : {
        200: {
          description: dynamicApi.responseDescription || "",
          content: {
            "application/json": {},
            "application/xml": {},
          },
        },
      };
}

function getBodyParam(api: DynamicApi): OpenAPIV3.RequestBodyObject {
  let requestBody = null;
  let apiRequestExample = api.requestExample;

  /* This need for backward compitability */
  if (apiHasBody(api.verb) && api.dataModel) {
    const match = JSON.stringify(api.dataModel).match(
      /(?=.*in)(?=.*path)|(?=.*in)(?=.*query)|(?=.*in)(?=.*header)/g
    );

    const isEmptyDataModel = checkDataModel(api.dataModel);

    if (!match && !isEmptyDataModel) {
      apiRequestExample = api.dataModel as OpenAPIV3.ExampleObject;
    }
  }

  if (api.requestExample) {
    requestBody = {
      description: "body param generated from provided JSON request example",
      required: true,
      content: {
        "application/json": {
          schema: generateSchemaFromJson(apiRequestExample),
        },
      },
    };
  }

  if (api.requestBody) {
    requestBody = api.requestBody;
  }

  return requestBody as OpenAPIV3.RequestBodyObject;
}

async function readApiFile(repositoryLocation: string): Promise<SerializedApi> {
  try {
    return (await readYaml(
      path.join(repositoryLocation, WORKSPACE_PATHS.APIS, "ace.yaml")
    )) as SerializedApi;
  } catch {
    console.debug("Creating new ace.yaml file.");
    return createEmptyApi();
  }
}

function apiHasBody(verb: string): boolean {
  return verb.toUpperCase() === "POST" || verb.toUpperCase() === "PUT";
}

function checkDataModel(dataModel: OpenAPIV3.ParameterObject[]): boolean {
  if (Array.isArray(dataModel) && dataModel.length === 0) {
    return true;
  }

  if (_.isObject(dataModel)) {
    return true;
  }

  return false;
}

export function getQueryParameters(
  apiDataModel: OpenAPIV3.ParameterObject[]
): OpenAPIV3.ParameterObject[] | [] {
  const params = [...apiDataModel.filter((x) => x.in === "query")];
  params.forEach((param) => {
    if (!param.schema) {
      param.schema = { type: "string" };
      delete (param as unknown as Record<string, unknown>).type;
    }
  });
  return [...params];
}

export function getPathParams(
  api: DynamicApi
): OpenAPIV3.ParameterObject[] | [] {
  const params: OpenAPIV3.ParameterObject[] = [];
  const paramsPattern = new RegExp("[^{}]+(?=})", "g");

  const { path } = api;
  const pathParams = path.match(paramsPattern);

  if (pathParams) {
    for (const param of pathParams) {
      const findParam =
        api.dataModel?.length && api.dataModel.find((p) => p.name === param);

      if (findParam) {
        if (!Object.prototype.hasOwnProperty.call(findParam, "schema")) {
          findParam.schema = { type: "string" };
        }

        findParam.required = true;
        findParam.description = findParam.description || "";
        params.push(findParam);
      } else {
        params.push(getPathParamObj(param));
      }
    }
  }

  if (api.dataModel?.length) {
    const definedPathParams = api.dataModel.filter((p) => p.in === "path");

    for (const param of definedPathParams) {
      const findParam = params.find((p) => p.name === param.name);

      if (!findParam) {
        params.push(getPathParamObj(param.name));
      }
    }
  }

  return params;
}

function getPathParamObj(name: string): OpenAPIV3.ParameterObject {
  return {
    in: "path",
    name: name,
    required: true,
    description: "",
    schema: {
      type: typeof name as OpenAPIV3.NonArraySchemaObjectType,
    },
  };
}

export function getHeaderParams(
  api: DynamicApi
): OpenAPIV3.ParameterObject[] | [] {
  let params: OpenAPIV3.ParameterObject[] = [];

  if (api.dataModel && Array.isArray(api.dataModel)) {
    params = [...api.dataModel.filter((x) => x.in === "header")];
    params.forEach((param) => {
      if (!param.schema) {
        param.schema = { type: "string" };
      }
    });
  }

  return params;
}

export default migrateApi;
