import { mergeVariablesContent } from "@sapiens-digital/ace-designer-common/lib/helpers/merge-workspace/mergeEnvs";

import { SerializedEnvVariable } from "../../../model/variable";
import {
  readWorkspaceVariableFile,
  saveWorkspaceVariablesFile,
} from "../import/importWorkspaceVariables";

import { PlainObject, V1EntityMigrator } from "./migrate";

const migrateWorkspaceVariable: V1EntityMigrator = async ({
  targetRepositoryLocation,
  repositoryLocation,
  content,
  overwriteExisting,
}) => {
  content = content as PlainObject[];
  const sourceFile = await readWorkspaceVariableFile(
    repositoryLocation as string
  );

  for (const variable of content) {
    variable["name"] = variable["key"];
    delete variable["key"];
  }

  if (overwriteExisting) {
    sourceFile.variables = content as unknown as SerializedEnvVariable[];
  } else {
    const variables = content as unknown as SerializedEnvVariable[];
    sourceFile.variables = mergeVariablesContent(
      { variables },
      sourceFile
    ).variables;
  }

  await saveWorkspaceVariablesFile(
    targetRepositoryLocation,
    sourceFile.variables
  );

  return [];
};

export default migrateWorkspaceVariable;
