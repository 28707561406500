const DEFAULT_GIT_USERNAME = "x-token-auth";

export const createGitCredentialsHeaders = (
  token: string,
  username = ""
): { [x: string]: string } => ({
  Authorization: `Basic ${btoaFn(
    `${username || DEFAULT_GIT_USERNAME}:${token}`
  )}`,
});

export const createGitCredentialsForGit = (
  token: string,
  username = ""
): Record<string, unknown> => {
  return {
    headers: createGitCredentialsHeaders(
      token,
      username || DEFAULT_GIT_USERNAME
    ),
  };
};

export const btoaFn = (data: string): string => {
  // This is the same encoding used internally by @isomorphic-git
  return Buffer.from(data).toString("base64");
};
