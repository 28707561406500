import { Page } from "../../../../model";
import { initialPageStateData } from "../../initialState";
import { PagesWithFileTrees } from "../FileTreeCaseReducers";

/**
 * Type guard for checking if page has a file tree in type definition for `Designer > page > [page]`
 * @param page
 */
export function pageHasFileTree<TPage extends Page>(
  page: TPage
): page is TPage & PagesWithFileTrees {
  return "selectedFolderId" in initialPageStateData[page];
}
