import { createAllPageParamSelectors } from "../utils/selectors/pageSelectors";

const api = createAllPageParamSelectors("api");
const flow = createAllPageParamSelectors("flow");
const schemas = createAllPageParamSelectors("schemas");
const virtualSteps = createAllPageParamSelectors("virtualSteps");
const variables = createAllPageParamSelectors("variables");
const scheduler = createAllPageParamSelectors("scheduler");
const handlers = createAllPageParamSelectors("handlers");

export const allPageSelectors: typeof api &
  typeof flow &
  typeof schemas &
  typeof virtualSteps &
  typeof variables &
  typeof scheduler &
  typeof handlers = {
  ...api,
  ...flow,
  ...schemas,
  ...virtualSteps,
  ...variables,
  ...scheduler,
  ...handlers,
};

export const {
  selectApiActiveToggleFilters,
  selectApiExpandedFolderIds,
  selectApiSearchTerm,
  selectApiSelectedFolderId,
  selectApiSelectedItemId,
  selectApiSelectedPage,
  selectApiSelectedTreeItemId,
  selectApiSort,
  selectApiUnsavedEntity,
  selectApiShowInfoAlert,
} = api;

export const {
  selectFlowActiveToggleFilters,
  selectFlowExpandedFolderIds,
  selectFlowSearchTerm,
  selectFlowSelectedFolderId,
  selectFlowSelectedItemId: selectPreviewFlowId,
  selectFlowSelectedPage,
  selectFlowSelectedTreeItemId,
  selectFlowSort,
  selectFlowUnsavedEntity,
  selectFlowView,
} = flow;

export const {
  selectSchemasActiveToggleFilters,
  selectSchemasExpandedFolderIds,
  selectSchemasSearchTerm,
  selectSchemasSelectedFolderId,
  selectSchemasSelectedItemId,
  selectSchemasSelectedPage,
  selectSchemasSelectedTreeItemId,
  selectSchemasSort,
  selectSchemasUnsavedEntity,
} = schemas;

export const {
  selectVirtualStepsActiveToggleFilters,
  selectVirtualStepsExpandedFolderIds,
  selectVirtualStepsSearchTerm,
  selectVirtualStepsSelectedFolderId,
  selectVirtualStepsSelectedItemId,
  selectVirtualStepsSelectedPage,
  selectVirtualStepsSelectedTreeItemId,
  selectVirtualStepsSort,
  selectVirtualStepsUnsavedEntity,
} = virtualSteps;

export const {
  selectVariablesActiveToggleFilters,
  selectVariablesExpandedFolderIds,
  selectVariablesSearchTerm,
  selectVariablesSelectedFolderId,
  selectVariablesSelectedItemId: selectVariablesId,
  selectVariablesSelectedPage,
  selectVariablesSelectedTreeItemId: selectVariablesFileId,
  selectVariablesSort,
  selectVariablesUnsavedEntity,
} = variables;

export const {
  selectSchedulerActiveToggleFilters,
  selectSchedulerSearchTerm,
  selectSchedulerSelectedItemId,
  selectSchedulerSelectedPage,
  selectSchedulerSort,
  selectSchedulerUnsavedEntity,
} = scheduler;

export const {
  selectHandlersActiveToggleFilters,
  selectHandlersSearchTerm,
  selectHandlersSelectedItemId,
  selectHandlersSelectedPage,
  selectHandlersSort,
  selectHandlersUnsavedEntity,
} = handlers;
