import { posixPath } from "@sapiens-digital/ace-designer-common/lib/helpers/posixPath";
import get from "lodash/get";
import { getOperationReqBodyFirstSupportedMediaType } from "utils/apis/getMediaType";

import { AceApiOperation, Api, SerializedApi } from "../model";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/apis/selectors";

import { updateUsage } from "./indexing/updateUsage";
import { DeleteEntity, GetEntity, SaveEntity } from "./entityService.types";
import { deleteFile, getFileDisplayName, readFile, saveFile } from "./fs-utils";

/**
 * Obtains the api from workspace
 * @param id api id
 * @param workspace workspace
 * @returns api stored in file system
 */
export const getApi: GetEntity<Api> = async (id, workspace) => {
  try {
    const fileApi = await readFile(id, workspace, "apis");
    const fileName = getFileDisplayName(id, workspace, "apis");
    return deserializeApi(id, fileName, fileApi as SerializedApi);
  } catch (e) {
    console.error(e);
    throw new Error(`Api with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves api to workspace
 * @param api dynamic api to save
 * @param workspace workspace
 * @returns saved api
 */
export const saveApi: SaveEntity<Api> = async (api, workspace) => {
  const name = nameSelector(api);

  try {
    const cleanApi = serializeApi(api);
    await saveFile(api.id, workspace, "apis", cleanApi, name);
    return api;
  } catch (e) {
    console.error(e);
    throw new Error(`Api "${name}" can not be saved`);
  }
};

/**
 * Deletes api from workspace by id
 * @param id api id
 * @param workspace workspace
 */
export const deleteApi: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "apis");
  } catch (e) {
    console.error(e);
    throw new Error(`Api with the id "${id}" can not be deleted`);
  }
};

export const deserializeApi = (
  id: string,
  fileName: string,
  fileApi: SerializedApi
): Api => ({
  ...fileApi,
  id,
  name: fileName,
});

export const serializeApi = (api: Api): SerializedApi => {
  const { id, name, ...rest } = api;
  return rest;
};

export const initApiReferences = (
  allOperations: AceApiOperation[],
  workspace: Workspace
): void => {
  allOperations.forEach((operation) => {
    updateApiUsage(operation, workspace);
  });
};

export const updateApiUsage = (
  operation: AceApiOperation,
  workspace: Workspace,
  previousId?: string
): void => {
  const usedSchemas = new Set<string | undefined>();

  operation.parameters?.forEach((param) => {
    const schemaName = get(param, "schema.$ref");
    usedSchemas.add(schemaName);
  });

  const requestBodyMediaType =
    getOperationReqBodyFirstSupportedMediaType(operation);
  const requestBodySchemaName = get(
    operation,
    `requestBody.content.${requestBodyMediaType}.schema.$ref`
  );
  usedSchemas.add(requestBodySchemaName);

  const responses = operation.responses;
  Object.values(responses).forEach((response) => {
    const schemaName = get(response, "content.application/json.schema.$ref");
    usedSchemas.add(schemaName);
  });

  const schemas = Array.from(usedSchemas)
    .map((schemaName) =>
      schemaName?.split(posixPath.sep).slice(1).join(posixPath.sep)
    )
    .filter(Boolean);

  updateUsage(
    { id: operation.id, entityType: "operations", previousId },
    { schemas, flows: [operation["x-ace-flow"]] },
    workspace
  );
};
