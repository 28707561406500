import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  deleteSchedule,
  getSchedule,
  saveSchedule,
  serializeSchedule,
} from "../../services/schedules";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
} from "../utils/actionFactory";
import { processExecuteFlow } from "../utils/executeFlowHelper";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import { RootState } from "..";

import { nameSelector, selectSchedule } from "./selectors";
import { name } from "./sliceName";

export const openScheduleAction = openEntityActionCreator(name, getSchedule);
export const storeScheduleAction = storeEntityActionCreator(
  name,
  saveSchedule,
  "schedules"
);
export const deleteScheduleAction = deleteEntityActionCreator(
  name,
  deleteSchedule,
  "schedules"
);

export const addScheduleToGitAction = addEntityToGitActionCreator(
  name,
  "schedules"
);

export const storeAndPushScheduleAction = storeAndPushActionCreator(
  name,
  nameSelector,
  storeScheduleAction,
  addScheduleToGitAction
);

export const deleteAndPushScheduleAction = deleteAndPushActionCreator(
  name,
  deleteScheduleAction,
  addScheduleToGitAction
);

export const refreshSchedulesAction = refreshEntitiesActionCreator(
  name,
  getSchedule,
  serializeSchedule
);

export const testSchedulerAction = createAsyncThunk<
  { result: unknown },
  {
    schedulerId: string;
    inputDoc: Record<string, unknown>;
  },
  { state: RootState }
>(
  `${name}/testSchedulerAction`,
  async (
    { schedulerId, inputDoc },
    { getState }
  ): Promise<{ result: unknown }> => {
    const state = getState();
    const currentWorkspace = selectSelectedWorkspace(state);
    let result = {};
    const scheduler = selectSchedule(state, schedulerId);

    if (!scheduler) {
      throw new Error("Scheduler could not be found");
    }

    const name = scheduler?.flowName?.includes(".yaml")
      ? scheduler?.flowName.replace(".yaml", "")
      : scheduler?.flowName ?? "";

    if (!name) {
      throw new Error("No flow name present");
    }

    result = await processExecuteFlow(
      {
        flow: name,
        doc: inputDoc,
      },
      state.settings,
      currentWorkspace
    );

    return { result };
  }
);
