import { Designer, Pages } from "../../../../model";
import { PageData } from "../CommonPageDataCaseReducers";

export function setUnsavedEntity<TPage extends keyof Pages>(
  state: Designer,
  page: TPage,
  unsavedEntity: PageData<TPage>["unsavedEntity"]
): void {
  (state.pages[page] as PageData<TPage>).unsavedEntity = unsavedEntity;
}
