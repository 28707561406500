import { getDefaultFormState as getDefaultFormStateRJSF } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv6";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Tail<T extends unknown[]> = T extends [infer A, ...infer R] ? R : never;

export const getDefaultFormState = (
  ...rest: Tail<Parameters<typeof getDefaultFormStateRJSF>>
): ReturnType<typeof getDefaultFormStateRJSF> =>
  getDefaultFormStateRJSF(validator, ...rest);
