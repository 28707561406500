import { PayloadAction } from "@reduxjs/toolkit";

import { Designer, FileTreeData, Pages } from "../../../model";
import { PickKeysOfType } from "../../../utils/types/PickKeysOfType";

import { setPageProperty } from "./utils/setPageProperty";
import { toggleExpandedFolder } from "./utils/toggleExpandedFolder";
import { keyVal, toSnakeCase } from "./utils";

export type PagesWithFileTrees = PickKeysOfType<Pages, FileTreeData>;

const getFileTreeCaseReducers = <TPage extends PagesWithFileTrees>(
  page: TPage
) => ({
  ...keyVal(
    toSnakeCase("select", page, "folderId"),
    (state: Designer, action: PayloadAction<string>) => {
      setPageProperty(state, page, "selectedFolderId", action.payload);
    }
  ),
  ...keyVal(
    toSnakeCase("select", page, "TreeItemId"),
    (state: Designer, action: PayloadAction<string>) => {
      setPageProperty(state, page, "selectedTreeItemId", action.payload);
    }
  ),
  ...keyVal(
    toSnakeCase("toggle", page, "folder"),
    (state: Designer, { payload }: PayloadAction<string>) => {
      toggleExpandedFolder(state, page, payload);
    }
  ),
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFileTreeDataCaseReducers = <TPage extends PagesWithFileTrees>(
  page: TPage
) => getFileTreeCaseReducers<TPage>(page);
