import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const mixedStepSchema: StepSchema = {
  stepType: Steps.MIXEDFLOW,
  color: "rgb(189,208,196)",
  jsonSchema: {
    description: "Extended multiflow step definition",
    title: "Mixed-flow",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          mode: {
            title: "Mode",
            type: "string",
            enum: ["flow", "bind"],
            default: "flow",
          },
          async: {
            type: "boolean",
            title: "Execute flows in parallel",
            default: false,
            enum: [true, false],
          },
          isBackground: {
            type: "boolean",
            title: "Execute flows in background",
            default: false,
            enum: [true, false],
          },
        },
        dependencies: {
          mode: {
            oneOf: [
              {
                required: ["flowIds"],
                properties: {
                  mode: {
                    enum: ["flow"],
                  },
                  flowIds: {
                    type: "array",
                    title: "List of flows to execute",
                    minItems: 1,
                    items: {
                      properties: {
                        flowId: {
                          type: "string",
                          title: "Flow name",
                        },
                        payload: {
                          type: "string",
                          title: "Flow payload path (doc - by default)",
                        },
                        targetPath: {
                          type: "string",
                          title:
                            "Target path - define the path in which to store the flow result (original flow target if not defined)",
                        },
                        processAsArray: {
                          type: "boolean",
                          title: "Process data as array",
                          default: false,
                          enum: [true, false],
                        },
                        cacheable: {
                          type: "boolean",
                          title: "Store target path results in cache",
                        },
                      },
                      dependencies: {
                        cacheable: {
                          oneOf: [
                            {
                              properties: {
                                cacheable: {
                                  enum: [true],
                                },
                                cacheKey: {
                                  type: "string",
                                  title: "Cache Key",
                                },
                                cacheCleanupCondition: {
                                  type: "string",
                                  title: "Cache clean-up condition",
                                  pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                                },
                                ttl: {
                                  type: "number",
                                  title: "TTL cache (minutes)",
                                },
                              },
                            },
                            {
                              properties: {
                                cacheable: {
                                  enum: [false],
                                },
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
              },
              {
                properties: {
                  mode: {
                    enum: ["bind"],
                  },
                  bindingPath: {
                    type: "string",
                    title:
                      "Binded path - define the path to which flows is binded, flow object must contain 'flowId'. Other params like 'payload' and 'targetPath' - optional",
                  },
                },
              },
            ],
          },
          async: {
            oneOf: [
              {
                properties: {
                  async: {
                    enum: [true],
                  },
                  concurrency: {
                    type: "number",
                    title: "concurrency - num of flows per async call",
                    default: 0,
                  },
                },
              },
              {
                properties: {
                  async: {
                    enum: [false],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      flowIds: {
        items: {
          flowId: {
            "ui:field": "flowSearch",
          },
        },
      },
    },
  },
};
