import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ButtonComponent from "@sapiens-digital/ace-designer-app/app/components/ButtonComponent";
import { useAppDispatch } from "@sapiens-digital/ace-designer-app/app/store";
import { addNotification } from "@sapiens-digital/ace-designer-app/app/store/designer/actions";
import { setUsername } from "@sapiens-digital/ace-designer-app/app/store/designer/reducers";
import { getAuthorizationUser } from "@sapiens-digital/ace-designer-app/app/store/utils/getAuthorization";

import { useToken } from "./useToken";
import { getIdpAuthUrl, isAuthenticationEnabled } from "./utils";

interface LoginPopupProps {
  onLogin: () => void;
}

const LoginPopup: React.FC<LoginPopupProps> = ({ onLogin }) => {
  const { token, clearToken } = useToken();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticationEnabled() && !token) {
      setOpen(true);
    } else if (!isAuthenticationEnabled() || token) {
      setOpen(false);
      onLogin();
    }
    //Need to run onLogin only if token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const { username } = getAuthorizationUser();
    dispatch(setUsername(username ?? null));
  }, [dispatch, token]);

  useEffect(() => {
    const onFailedAuthForRequest = (): void => {
      setOpen(true);
      clearToken();
    };

    window.addEventListener("authfail", onFailedAuthForRequest);
    return () => {
      window.removeEventListener("authfail", onFailedAuthForRequest);
    };
  }, [clearToken]);

  useEffect(() => {
    const onFailLoadSettings = (): void => {
      token &&
        dispatch(
          addNotification({
            message: `Failure in loading user settings`,
            variant: "error",
          })
        );
    };
    window.addEventListener("loadSettingsFail", onFailLoadSettings);
    return () => {
      window.removeEventListener("loadSettingsFail", onFailLoadSettings);
    };
  }, [token, dispatch]);

  const login = useCallback((): void => {
    window.open(getIdpAuthUrl());
  }, []);

  const onClose = () => !isAuthenticationEnabled() && setOpen(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Designer server login</DialogTitle>
      <DialogContent>You must be logged in to continue</DialogContent>
      <DialogActions sx={{ p: 2 }}>
        {!isAuthenticationEnabled() && (
          <ButtonComponent
            variant="text"
            onClick={onClose}
            data-testid="login-popup-close-btn"
          >
            Cancel
          </ButtonComponent>
        )}
        <ButtonComponent variant="contained" onClick={login}>
          Open login page in new window
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default LoginPopup;
