import { Errors } from "isomorphic-git";

// This file contains utilities intended for use in git-utils.ts

const UNAUTHORIZED_ERROR_CODE = 401;

export const hasAccessTokenExpired = (e: unknown): boolean =>
  (e instanceof TypeError && e.message === "Failed to fetch") ||
  (e instanceof Errors.HttpError &&
    e.data.statusCode === UNAUTHORIZED_ERROR_CODE);
